import { Chip } from '@mui/material'
import React from 'react'
import { Registration } from '../../../../../dal'

interface RegistrationCompletenessIndicatorProps{
    registration: Registration;
}

export default function RegistrationCompletenessIndicator(props : RegistrationCompletenessIndicatorProps) {
    return (
        <div>
            {props.registration.isComplete && 
                <Chip  label="Complete" />
            }
            {!props.registration.isComplete && 
                <Chip  label="Incomplete" />
            }
        </div>
    )
}
