import { LocalizationProvider } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Stack, Button, MenuItem, LinearProgress, useMediaQuery, Paper, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useContext } from 'react'
import { MainPageContext } from '../../../context/MainPageContext';
import MomentAdapter from '@mui/lab/AdapterMoment';
import { Select, TextField } from 'formik-mui';
import { GridRowId } from '@mui/x-data-grid';
import RegistrationDatePicker from './RegistrationDatePicker/RegistrationDatePicker';
import { theme } from '../../../theme/MainTheme';
import { GetRegistrationLabel, GetRegistrationType } from '../../../utils/RegistrationMapper';
import moment from 'moment';
import { validationText1, validationText2 } from '../../../resources/text/text';
import { Registration } from '../../../dal';

interface AddRegistrationDialogProps {
    show: boolean;
    handleClose: () => void;
    selectedAthletes: GridRowId[];
    selectedRegistration: Registration | null;
}

export interface AddRegistrationValues {
    toDate: moment.Moment
    fromDate: moment.Moment
    registrationType: string
    buildingName: string | null | undefined
    roomNumber: string | null | undefined
    floor: string | null | undefined
    streetAddress: string | null | undefined
    city: string | null | undefined
    additionalComments: string | null | undefined
}


export default function AddRegistrationDialog(props: AddRegistrationDialogProps) {

    moment.tz.setDefault('GMT')

    const { buildings, addRegistration, updateRegistration, refreshAll } = useContext(MainPageContext);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const offset = (new Date()).getTimezoneOffset();

    const getMissingData = (values: AddRegistrationValues): string[] => {

        let retValues = [];

        if (values.registrationType.indexOf('Olympic') > -1) {
            if (values.buildingName === '') {
                retValues.push('building name');
            }
            if (values.roomNumber === '') {
                retValues.push('room number');
            }
        }

        if (values.registrationType === 'Hotel') {
            if (values.streetAddress === '') {
                retValues.push('street address');
            }
            if (values.city === '') {
                retValues.push('city');
            }
            if (values.buildingName === '') {
                retValues.push('building name');
            }
        }

        if (values.registrationType === 'Private') {
            if (values.streetAddress === '') {
                retValues.push('street address');
            }
            if (values.city === '') {
                retValues.push('city');
            }
        }

        return retValues;

    }

    return (
        <div>
            <Dialog open={props.show} onClose={props.handleClose} fullScreen={fullScreen} fullWidth>
                <DialogTitle>Add a new rooming registration</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a new registrations to the database.
                    </DialogContentText>
                    <LocalizationProvider dateAdapter={MomentAdapter}>
                        <Formik
                            initialValues={{
                                toDate: props.selectedRegistration ? moment(props.selectedRegistration.toDate) : moment("2022/02/01").add(-offset, 'minutes'),
                                fromDate: props.selectedRegistration ? moment(props.selectedRegistration.fromDate) : moment("2022/02/01").add(-offset, 'minutes'),
                                registrationType: props.selectedRegistration ? GetRegistrationLabel(props.selectedRegistration.registrationType!) : "Olympic Village",
                                buildingName: props.selectedRegistration ? props.selectedRegistration.buildingName : "",
                                roomNumber: props.selectedRegistration ? props.selectedRegistration.roomNumber : "",
                                floor: props.selectedRegistration ? props.selectedRegistration.floor : "",
                                streetAddress: props.selectedRegistration ? props.selectedRegistration.streetAddress : "",
                                city: props.selectedRegistration ? props.selectedRegistration.city : "",
                                additionalComments: props.selectedRegistration ? props.selectedRegistration.additionalComments : "",
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                if (props.selectedAthletes.length > 0) {
                                    // eslint-disable-next-line
                                    await Promise.all(
                                        props.selectedAthletes.map(async a => {

                                            await addRegistration({
                                                buildingName: values.buildingName,
                                                fromDate: values.fromDate.tz('GMT').hour(0).toDate(),
                                                toDate: values.toDate.tz('GMT').hour(0).toDate(),
                                                athleteId: Number.parseInt(a.toString()),
                                                roomNumber: values.roomNumber,
                                                floor: values.floor,
                                                streetAddress: values.streetAddress,
                                                city: values.city,
                                                additionalComments: values.additionalComments,
                                                registrationType: GetRegistrationType(values.registrationType)
                                            })
                                        }));

                                    await refreshAll();
                                }
                                else {
                                    if (props.selectedRegistration) {
                                        await updateRegistration({
                                            registrationId: props.selectedRegistration.registrationId,
                                            buildingName: values.buildingName,
                                            fromDate: values.fromDate.tz('GMT').hour(0).toDate(),
                                            toDate: values.toDate.tz('GMT').hour(0).toDate(),
                                            athleteId: props.selectedRegistration.athleteId!,
                                            roomNumber: values.roomNumber,
                                            floor: values.floor,
                                            streetAddress: values.streetAddress,
                                            city: values.city,
                                            additionalComments: values.additionalComments,
                                            registrationType: GetRegistrationType(values.registrationType)
                                        });
                                        refreshAll();
                                    }
                                }

                                props.handleClose();

                            }}
                        >
                            {({ submitForm, isSubmitting, values }) => (
                                <Form>
                                    <Stack spacing={3} style={{ marginTop: "20px", marginBottom: "20px" }}>

                                        <Field
                                            component={Select}
                                            //formHelperText={{ children: 'How old are you?' }}
                                            id="registrationType"
                                            name="registrationType"
                                            labelId="reg-type-label"
                                            label="Registration type"
                                        >
                                            <MenuItem key={"Zhangjiakou Olympic Village"} value={"Zhangjiakou Olympic Village"}>
                                                Zhangjiakou Olympic Village
                                            </MenuItem>
                                            <MenuItem key={"Beijing Olympic Village"} value={"Beijing Olympic Village"}>
                                                Beijing Olympic Village
                                            </MenuItem>
                                            <MenuItem key={"Yanqing Olympic Village"} value={"Yanqing Olympic Village"}>
                                                Yanqing Olympic Village
                                            </MenuItem>
                                            <MenuItem key={"Hotel"} value={"Hotel"}>
                                                Hotel
                                            </MenuItem>
                                            <MenuItem key={"Private"} value={"Private"}>
                                                Private
                                            </MenuItem>
                                        </Field>

                                        {values.registrationType.indexOf("Olympic Village") > -1 &&

                                            <Field
                                                component={Select}
                                                //formHelperText={{ children: 'How old are you?' }}
                                                id="buildingName"
                                                name="buildingName"
                                                labelId="building-name-label"
                                                label="Building name"
                                            >
                                                {
                                                    buildings.filter(b => b.buildingLocation === values.registrationType).map(b => {

                                                        return <MenuItem value={b.buildingName!} key={b.buildingId}>{b.buildingName!}</MenuItem>
                                                    })
                                                }
                                            </Field>
                                        }

                                        {values.registrationType.indexOf("Olympic Village") === -1 &&
                                            <Field
                                                component={TextField}
                                                name="buildingName"
                                                label="Building name"
                                                textField={{ helperText: '', variant: 'filled' }}
                                            />
                                        }


                                        {values.registrationType.indexOf("Olympic Village") === -1 &&
                                            <Field
                                                component={TextField}
                                                name="city"
                                                label="City"
                                                textField={{ helperText: '', variant: 'filled' }}
                                            />
                                        }
                                        {values.registrationType.indexOf("Olympic Village") === -1 &&
                                            <Field
                                                component={TextField}
                                                name="streetAddress"
                                                label="Address"
                                                textField={{ helperText: '', variant: 'filled' }}
                                            />
                                        }


                                        <Stack direction={'row'} spacing={2}>
                                            <Field
                                                component={RegistrationDatePicker}
                                                name="fromDate"
                                                label="From date"
                                                textField={{ helperText: '', variant: 'filled' }}
                                                validate={(d: moment.Moment) => {
                                                    if (!d) return 'Please pick a date!';
                                                    if (values.fromDate < d) return 'Dates are in the incorrect order!';
                                                    return null;
                                                }
                                                }
                                            />
                                            <Field
                                                component={RegistrationDatePicker}
                                                name="toDate"
                                                label="To date"
                                                textField={{ helperText: '', variant: 'filled' }}
                                                validate={(d: moment.Moment) => {
                                                    if (!d) return 'Please pick a date!';
                                                    if (values.fromDate > d) return 'Dates are in the incorrect order!';
                                                    return null;
                                                }
                                                }
                                            />
                                        </Stack>

                                        <Stack direction={'row'} spacing={2}>
                                            <Field fullWidth
                                                component={TextField}
                                                name="roomNumber"
                                                label="Room and door number"
                                                textField={{ helperText: '', variant: 'filled' }}
                                            />
                                            <Field fullWidth
                                                component={TextField}
                                                name="floor"
                                                label="Floor"
                                                textField={{ helperText: '', variant: 'filled' }}
                                            />
                                        </Stack>

                                        <Field
                                            component={TextField}
                                            name="additionalComments"
                                            label="Comments"
                                            textField={{ helperText: '', variant: 'filled' }}
                                        />

                                        {
                                            getMissingData(values).length > 0 &&
                                            <Paper sx={{ padding: '10px 10px 10px 10px' }}>
                                                <Typography variant='h5'>
                                                    {validationText1}
                                                    {
                                                        getMissingData(values).map((m, index) => { return <b>{m}, </b> })
                                                    }.

                                                    <br />

                                                    {validationText2}
                                                </Typography>
                                            </Paper>
                                        }


                                        {isSubmitting && <LinearProgress />}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            Submit
                                        </Button>
                                        <Button variant="contained" color="error" onClick={() => props.handleClose()}>Close</Button>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </LocalizationProvider>
                </DialogContent>
            </Dialog>
        </div>
    )
}