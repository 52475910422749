import { Alert, Dialog, Grid, Link, Typography } from '@mui/material'
import saveAs from 'file-saver';
import React, { useContext } from 'react'
import { MainPageContext } from '../../context/MainPageContext'
import { updateBuilding2, welcomeText1, welcomeText2 } from '../../resources/text/text';
import StatCard from './StatCard/StatCard';

interface WelcomePageDialogProps {
    show: boolean;
    handleClose: () => void;
}


export default function WelcomePage(p: WelcomePageDialogProps) {

    const { currentUser } = useContext(MainPageContext);

    const downloadBuildingNames = () => {
        saveAs("/files/OlympicVillageBuildingNames.pdf", "BuildingNames.pdf");
    }

    return (
        <Dialog open={p.show} onClose={p.handleClose} fullWidth maxWidth={'lg'}>
            <div>
                <Grid style={{ padding: '20px 20px 20px 20px' }} container spacing={3}>
                    <Grid item sm={12}>
                        <Typography gutterBottom variant="h4">
                            Welcome {currentUser?.firstName} {currentUser?.lastName} ({currentUser?.country?.countryName}),
                        </Typography>
                        <Alert severity="warning">
                            {updateBuilding2} <Link onClick={() => downloadBuildingNames()}>Download</Link>
                        </Alert>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography> {welcomeText1} </Typography>
                        <Typography> {welcomeText2} </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard type={'all_athletes'}></StatCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard type={'complete_registration'}></StatCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard type={'incomplete_registration'}></StatCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <StatCard type={'no_registration'}></StatCard>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    )
}
