import { Configuration, PopupRequest } from "@azure/msal-browser";

import {frontendBasePath, apiAuthority,apiKey, apiScopes} from './config'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: apiKey ,
        authority: apiAuthority,
        redirectUri: frontendBasePath
        }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: apiScopes
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}