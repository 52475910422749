/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryStatistic
 */
export interface CountryStatistic {
    /**
     * 
     * @type {string}
     * @memberof CountryStatistic
     */
    countryName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CountryStatistic
     */
    totalNumberOfAthletes?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryStatistic
     */
    totalNumberOfRegistrations?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryStatistic
     */
    athletesWithCompleteRegistrations?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryStatistic
     */
    athletesWithIncompleteRegistrations?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryStatistic
     */
    athletesWithNoRegistrations?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryStatistic
     */
    activeBadgeCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryStatistic
     */
    delegationSize?: number;
}

export function CountryStatisticFromJSON(json: any): CountryStatistic {
    return CountryStatisticFromJSONTyped(json, false);
}

export function CountryStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryStatistic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'totalNumberOfAthletes': !exists(json, 'totalNumberOfAthletes') ? undefined : json['totalNumberOfAthletes'],
        'totalNumberOfRegistrations': !exists(json, 'totalNumberOfRegistrations') ? undefined : json['totalNumberOfRegistrations'],
        'athletesWithCompleteRegistrations': !exists(json, 'athletesWithCompleteRegistrations') ? undefined : json['athletesWithCompleteRegistrations'],
        'athletesWithIncompleteRegistrations': !exists(json, 'athletesWithIncompleteRegistrations') ? undefined : json['athletesWithIncompleteRegistrations'],
        'athletesWithNoRegistrations': !exists(json, 'athletesWithNoRegistrations') ? undefined : json['athletesWithNoRegistrations'],
        'activeBadgeCount': !exists(json, 'activeBadgeCount') ? undefined : json['activeBadgeCount'],
        'delegationSize': !exists(json, 'delegationSize') ? undefined : json['delegationSize'],
    };
}

export function CountryStatisticToJSON(value?: CountryStatistic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryName': value.countryName,
        'totalNumberOfAthletes': value.totalNumberOfAthletes,
        'totalNumberOfRegistrations': value.totalNumberOfRegistrations,
        'athletesWithCompleteRegistrations': value.athletesWithCompleteRegistrations,
        'athletesWithIncompleteRegistrations': value.athletesWithIncompleteRegistrations,
        'athletesWithNoRegistrations': value.athletesWithNoRegistrations,
        'activeBadgeCount': value.activeBadgeCount,
        'delegationSize': value.delegationSize,
    };
}

