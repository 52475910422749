import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useContext } from 'react'
import { AthleteDetailsContext } from '../../../../../context/AthleteDetailsContext'

interface RegistrationDeleteConfirmationProps{
    registrationId: number;
    show: boolean;
    handleClose: () => void;
}


export default function RegistrationDeleteConfirmation(props : RegistrationDeleteConfirmationProps) {

    const {deleteRegistration} = useContext(AthleteDetailsContext);
    
    const handleDelete = async () => {
        await deleteRegistration(props.registrationId);
        props.handleClose();
    }

    return (
        <div>
             <Dialog open={props.show} onClose={props.handleClose}>
                <DialogTitle>Delete confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the registration?
                    </DialogContentText>
                        <DialogActions>
                            <Button onClick={() => handleDelete()}>Delete</Button>
                            <Button onClick={() => props.handleClose()} >Cancel</Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}
