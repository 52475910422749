/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryToJSON,
} from '../models';

export interface ApiCountryIdDeleteRequest {
    id: number;
}

export interface ApiCountryIdGetRequest {
    id: number;
}

export interface ApiCountryIdPutRequest {
    id: number;
    body?: string;
}

export interface ApiCountryPostRequest {
    country?: Country;
}

/**
 * 
 */
export class CountryApi extends runtime.BaseAPI {

    /**
     */
    async apiCountryGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Country>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Country`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountryFromJSON));
    }

    /**
     */
    async apiCountryGet(initOverrides?: RequestInit): Promise<Array<Country>> {
        const response = await this.apiCountryGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCountryIdDeleteRaw(requestParameters: ApiCountryIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCountryIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Country/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCountryIdDelete(requestParameters: ApiCountryIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCountryIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCountryIdGetRaw(requestParameters: ApiCountryIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Country>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCountryIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Country/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryFromJSON(jsonValue));
    }

    /**
     */
    async apiCountryIdGet(requestParameters: ApiCountryIdGetRequest, initOverrides?: RequestInit): Promise<Country> {
        const response = await this.apiCountryIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCountryIdPutRaw(requestParameters: ApiCountryIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCountryIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Country/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCountryIdPut(requestParameters: ApiCountryIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCountryIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCountryPostRaw(requestParameters: ApiCountryPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Country`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CountryToJSON(requestParameters.country),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCountryPost(requestParameters: ApiCountryPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCountryPostRaw(requestParameters, initOverrides);
    }

}
