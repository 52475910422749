import { Typography } from '@mui/material'
import React, { useState } from 'react'
import styles from './LoginPage.module.css'
import logo from '../../resources/images/ita_color_rgb_big.png'
import LoginArea from './LoginArea/LoginArea'
import CookiePolicy from './CookiePolicy/CookiePolicy'

export default function LoginPage() {

    const [showCookieDialog, setShowCookieDialog] = useState(false);

    return (
        <div className={styles.loginContainer}>
            <div className={styles.parent}>
                <div className={styles.box}>
                    <div>
                        <LoginArea></LoginArea>
                    </div>
                </div>
            </div>
            <div className={styles.bar}>
                <div style={{ display: 'flex', alignItems: "center", height: '100%', padding: '0px 30px 0px 30px', justifyContent: 'space-between' }}>
                    <img src={logo} height="30" alt="ITA logo"></img>
                    <div>
                        <div onClick={() => { setShowCookieDialog(true) }}>
                            <Typography variant="h5">
                                Cookie policy
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>

            <CookiePolicy show={showCookieDialog} handleClose={() => setShowCookieDialog(false)}></CookiePolicy>

        </div>
    )
}