import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { MainPageContext } from '../../../context/MainPageContext';


interface StatCardProps {
    type: string;
}

export default function StatCard(p: StatCardProps) {

    const { athletes, setAthleteListFilter, setShowWelcomePage } = useContext(MainPageContext);

    const getColor = (): string => {
        switch (p.type) {
            case "all_athletes": {
                return '#d1ddff';
            }
            case "complete_registration": {
                return '#dbffd1';
            }
            case "incomplete_registration": {
                return '#fdffbf';
            }
            case "no_registration": {
                return '#fe6269';
            }
            default: {
                return '#d1ddff';
            }
        }
    }

    const getValue = (): string => {
        switch (p.type) {
            case "all_athletes": {
                return athletes.length.toString();
            }
            case "complete_registration": {
                return (athletes.filter(a => a.registrations!.filter(r => r.isComplete !== true).length === 0 && a.registrations!.length > 0).length).toString();
            }
            case "incomplete_registration": {
                return (athletes.filter(a => a.registrations!.filter(r => r.isComplete !== true).length !== 0).length).toString();
            }
            case "no_registration": {
                return (athletes.filter(a => a.registrations!.length === 0).length).toString();
            }
            default: {
                return (athletes.filter(a => a.registrations!.length === 0).length).toString();
            }
        }
    }

    const getTitle = (): string => {
        switch (p.type) {
            case "all_athletes": {
                return "All registered athletes";
            }
            case "complete_registration": {
                return "Athletes with only complete registrations";
            }
            case "incomplete_registration": {
                return "Athletes with incomplete registrations";
            }
            case "no_registration": {
                return "Athletes with no registrations";
            }
            default: {
                return "All registered athletes";
            }
        }
    }

    const filterList = () => {


        let newFilter = {
            items: [{ columnField: 'status', value: 'Complete', operatorValue: 'equals' }],
        };

        switch (p.type) {
            case "all_athletes": {
                newFilter = {
                    items: [],
                };
                break;
            }
            case "complete_registration": {
                newFilter = {
                    items: [{ columnField: 'status', value: 'Complete', operatorValue: 'equals' }],
                };
                break;
            }
            case "incomplete_registration": {
                newFilter = {
                    items: [{ columnField: 'status', value: 'Incomplete', operatorValue: 'equals' }],
                };
                break;
            }
            case "no_registration": {
                newFilter = {
                    items: [{ columnField: 'status', value: 'Missing', operatorValue: 'equals' }],
                };
                break;
            }
            default: {
                newFilter = {
                    items: [],
                };
                break;
            }
        }
            setAthleteListFilter(newFilter);
            setShowWelcomePage(false);
    }

    return (
        <Card style={{ backgroundColor: getColor() }}>
            <CardContent>
                <Grid maxHeight={'100px'} spacing={3}>
                    <Grid item minHeight={'100px'}>
                        <Typography color="textSecondary" gutterBottom>
                            {getTitle()}
                        </Typography>
                    </Grid>
                    <Typography variant="h4" component="h3">
                        {getValue()}
                    </Typography>
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={() => { filterList() }}>Go to list</Button>
            </CardActions>
        </Card>
    )
}
