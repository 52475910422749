/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationStaffInfo
 */
export interface RegistrationStaffInfo {
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    registrationID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    athleteId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    accreditationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    discipline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    disciplineCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    sport?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    sportCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    registrationType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationStaffInfo
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationStaffInfo
     */
    toDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    room?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    floor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegistrationStaffInfo
     */
    comments?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationStaffInfo
     */
    badgeActivationDate?: Date;
}

export function RegistrationStaffInfoFromJSON(json: any): RegistrationStaffInfo {
    return RegistrationStaffInfoFromJSONTyped(json, false);
}

export function RegistrationStaffInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationStaffInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationID': !exists(json, 'registrationID') ? undefined : json['registrationID'],
        'athleteId': !exists(json, 'athleteId') ? undefined : json['athleteId'],
        'accreditationNumber': !exists(json, 'accreditationNumber') ? undefined : json['accreditationNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'discipline': !exists(json, 'discipline') ? undefined : json['discipline'],
        'disciplineCode': !exists(json, 'disciplineCode') ? undefined : json['disciplineCode'],
        'sport': !exists(json, 'sport') ? undefined : json['sport'],
        'sportCode': !exists(json, 'sportCode') ? undefined : json['sportCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'registrationType': !exists(json, 'registrationType') ? undefined : json['registrationType'],
        'fromDate': !exists(json, 'fromDate') ? undefined : (new Date(json['fromDate'])),
        'toDate': !exists(json, 'toDate') ? undefined : (new Date(json['toDate'])),
        'city': !exists(json, 'city') ? undefined : json['city'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'badgeActivationDate': !exists(json, 'badgeActivationDate') ? undefined : (new Date(json['badgeActivationDate'])),
    };
}

export function RegistrationStaffInfoToJSON(value?: RegistrationStaffInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationID': value.registrationID,
        'athleteId': value.athleteId,
        'accreditationNumber': value.accreditationNumber,
        'name': value.name,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'discipline': value.discipline,
        'disciplineCode': value.disciplineCode,
        'sport': value.sport,
        'sportCode': value.sportCode,
        'country': value.country,
        'registrationType': value.registrationType,
        'fromDate': value.fromDate === undefined ? undefined : (value.fromDate.toISOString()),
        'toDate': value.toDate === undefined ? undefined : (value.toDate.toISOString()),
        'city': value.city,
        'gender': value.gender,
        'buildingName': value.buildingName,
        'room': value.room,
        'floor': value.floor,
        'address': value.address,
        'postalCode': value.postalCode,
        'comments': value.comments,
        'badgeActivationDate': value.badgeActivationDate === undefined ? undefined : (value.badgeActivationDate.toISOString()),
    };
}

