/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchPaging
 */
export interface SearchPaging {
    /**
     * 
     * @type {number}
     * @memberof SearchPaging
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchPaging
     */
    skip?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchPaging
     */
    orderBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchPaging
     */
    orderByDesc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchPaging
     */
    filterBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPaging
     */
    filterByValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchPaging
     */
    searchTerm?: string | null;
}

export function SearchPagingFromJSON(json: any): SearchPaging {
    return SearchPagingFromJSONTyped(json, false);
}

export function SearchPagingFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchPaging {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'size': !exists(json, 'size') ? undefined : json['size'],
        'skip': !exists(json, 'skip') ? undefined : json['skip'],
        'orderBy': !exists(json, 'orderBy') ? undefined : json['orderBy'],
        'orderByDesc': !exists(json, 'orderByDesc') ? undefined : json['orderByDesc'],
        'filterBy': !exists(json, 'filterBy') ? undefined : json['filterBy'],
        'filterByValue': !exists(json, 'filterByValue') ? undefined : json['filterByValue'],
        'searchTerm': !exists(json, 'searchTerm') ? undefined : json['searchTerm'],
    };
}

export function SearchPagingToJSON(value?: SearchPaging | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': value.size,
        'skip': value.skip,
        'orderBy': value.orderBy,
        'orderByDesc': value.orderByDesc,
        'filterBy': value.filterBy,
        'filterByValue': value.filterByValue,
        'searchTerm': value.searchTerm,
    };
}

