import { Button, Typography } from '@mui/material'
import React from 'react'
import styles from './LoginArea.module.css'
import { useMsal } from "@azure/msal-react"
import { loginRequest } from '../../../config/authConfig'

export default function LoginArea() {

    const { instance } = useMsal();

    return (
        <div className={styles.loginBox}>
            <Typography variant="h4">Welcome to the ITA Rooming App</Typography>

            <div className={styles.buttonBox}>
            <Button fullWidth variant="contained" size="large" onClick={() => { instance.loginPopup(loginRequest) }}>
                Login
            </Button>
            </div>
        </div>
    )
}
