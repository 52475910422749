import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import React, { useContext } from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import styles from './AppSideMenuUserOptions.module.css'
import { MainPageContext } from '../../../../context/MainPageContext';
import { GetUserRole } from '../../../../utils/UserMapper';
import { useMsal } from '@azure/msal-react';

export default function AppSideMenuUserOptions() {

    const {currentUser} = useContext(MainPageContext);
    const userRoles = GetUserRole(currentUser!);
    const { instance } = useMsal();
    
    return (
        <div className={styles.listContainer}>
                <List>
                    <ListItem>
                        {
                            userRoles && userRoles!.indexOf('RoomingAdmin') > -1 && 
                                <ListItemText primary={currentUser?.firstName + ' ' + currentUser?.lastName + " - Rooming Admin"} />
                        }
                        {
                            userRoles && userRoles!.indexOf('RoomingStaff') > -1 && 
                                <ListItemText primary={currentUser?.firstName + ' ' + currentUser?.lastName + " - Rooming Staff"} />
                        }
                        {
                            !userRoles  && 
                                <ListItemText primary={currentUser?.firstName + ' ' + currentUser?.lastName + " - " + currentUser?.country?.countryName} />
                        }
                        
                    </ListItem>
                    <ListItem button key={"download"}>
                        <ListItemIcon color="#e3e3e3">
                            <LogoutIcon color="info" />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} onClick={() => instance.logout()} />
                    </ListItem>
                </List>
        </div>
    )
}
