/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Athlete,
    AthleteFromJSON,
    AthleteToJSON,
    CountryStatistic,
    CountryStatisticFromJSON,
    CountryStatisticToJSON,
    Paging,
    PagingFromJSON,
    PagingToJSON,
    RegistrationStaffResult,
    RegistrationStaffResultFromJSON,
    RegistrationStaffResultToJSON,
    RoomingUser,
    RoomingUserFromJSON,
    RoomingUserToJSON,
    SearchPaging,
    SearchPagingFromJSON,
    SearchPagingToJSON,
    SearchResult,
    SearchResultFromJSON,
    SearchResultToJSON,
} from '../models';

export interface ApiAdminAthleteIdPostRequest {
    id: string;
}

export interface ApiAdminAthletesPostRequest {
    paging?: Paging;
}

export interface ApiAdminAthletesSearchPostRequest {
    searchPaging?: SearchPaging;
}

export interface ApiAdminCountriesMembersPostRequest {
    countryName?: string;
}

export interface ApiAdminCountriesStatisticsPostRequest {
    paging?: Paging;
}

export interface ApiAdminNotificationPostRequest {
    countryName?: string;
    message?: string;
    recipients?: string;
}

export interface ApiAdminRegistrationsDatePostRequest {
    date?: Date;
    paging?: Paging;
}

export interface ApiAdminRegistrationsIdPostRequest {
    id: number;
}

export interface ApiAdminRegistrationsPostRequest {
    paging?: Paging;
}

export interface ApiAdminRegistrationsSearchDatePostRequest {
    date?: Date;
    paging?: Paging;
}

export interface ApiAdminRegistrationsSearchPostRequest {
    paging?: Paging;
}

/**
 * 
 */
export class AdminApi extends runtime.BaseAPI {

    /**
     */
    async apiAdminAthleteIdPostRaw(requestParameters: ApiAdminAthleteIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Athlete>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdminAthleteIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Athlete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AthleteFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminAthleteIdPost(requestParameters: ApiAdminAthleteIdPostRequest, initOverrides?: RequestInit): Promise<Athlete> {
        const response = await this.apiAdminAthleteIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminAthletesGetCountPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Athletes/GetCount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminAthletesGetCountPost(initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiAdminAthletesGetCountPostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminAthletesPostRaw(requestParameters: ApiAdminAthletesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Athlete>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Athletes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PagingToJSON(requestParameters.paging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AthleteFromJSON));
    }

    /**
     */
    async apiAdminAthletesPost(requestParameters: ApiAdminAthletesPostRequest, initOverrides?: RequestInit): Promise<Array<Athlete>> {
        const response = await this.apiAdminAthletesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminAthletesSearchPostRaw(requestParameters: ApiAdminAthletesSearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Athletes/Search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchPagingToJSON(requestParameters.searchPaging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminAthletesSearchPost(requestParameters: ApiAdminAthletesSearchPostRequest, initOverrides?: RequestInit): Promise<SearchResult> {
        const response = await this.apiAdminAthletesSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminCountriesMembersPostRaw(requestParameters: ApiAdminCountriesMembersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RoomingUser>>> {
        const queryParameters: any = {};

        if (requestParameters.countryName !== undefined) {
            queryParameters['countryName'] = requestParameters.countryName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/countries/members`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoomingUserFromJSON));
    }

    /**
     */
    async apiAdminCountriesMembersPost(requestParameters: ApiAdminCountriesMembersPostRequest, initOverrides?: RequestInit): Promise<Array<RoomingUser>> {
        const response = await this.apiAdminCountriesMembersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminCountriesStatisticsPostRaw(requestParameters: ApiAdminCountriesStatisticsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CountryStatistic>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/countries/statistics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PagingToJSON(requestParameters.paging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountryStatisticFromJSON));
    }

    /**
     */
    async apiAdminCountriesStatisticsPost(requestParameters: ApiAdminCountriesStatisticsPostRequest, initOverrides?: RequestInit): Promise<Array<CountryStatistic>> {
        const response = await this.apiAdminCountriesStatisticsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminNotificationPostRaw(requestParameters: ApiAdminNotificationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.countryName !== undefined) {
            queryParameters['countryName'] = requestParameters.countryName;
        }

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        if (requestParameters.recipients !== undefined) {
            queryParameters['recipients'] = requestParameters.recipients;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAdminNotificationPost(requestParameters: ApiAdminNotificationPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAdminNotificationPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAdminRegistrationsDatePostRaw(requestParameters: ApiAdminRegistrationsDatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegistrationStaffResult>> {
        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Registrations/Date`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PagingToJSON(requestParameters.paging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationStaffResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminRegistrationsDatePost(requestParameters: ApiAdminRegistrationsDatePostRequest, initOverrides?: RequestInit): Promise<RegistrationStaffResult> {
        const response = await this.apiAdminRegistrationsDatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsDownloadGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/registrations/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiAdminRegistrationsDownloadGet(initOverrides?: RequestInit): Promise<object> {
        const response = await this.apiAdminRegistrationsDownloadGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsGetCountPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Registrations/GetCount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminRegistrationsGetCountPost(initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiAdminRegistrationsGetCountPostRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsIdPostRaw(requestParameters: ApiAdminRegistrationsIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegistrationStaffResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAdminRegistrationsIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Registrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationStaffResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminRegistrationsIdPost(requestParameters: ApiAdminRegistrationsIdPostRequest, initOverrides?: RequestInit): Promise<RegistrationStaffResult> {
        const response = await this.apiAdminRegistrationsIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsPostRaw(requestParameters: ApiAdminRegistrationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegistrationStaffResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Registrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PagingToJSON(requestParameters.paging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationStaffResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminRegistrationsPost(requestParameters: ApiAdminRegistrationsPostRequest, initOverrides?: RequestInit): Promise<RegistrationStaffResult> {
        const response = await this.apiAdminRegistrationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsSearchDatePostRaw(requestParameters: ApiAdminRegistrationsSearchDatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegistrationStaffResult>> {
        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Registrations/SearchDate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PagingToJSON(requestParameters.paging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationStaffResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminRegistrationsSearchDatePost(requestParameters: ApiAdminRegistrationsSearchDatePostRequest, initOverrides?: RequestInit): Promise<RegistrationStaffResult> {
        const response = await this.apiAdminRegistrationsSearchDatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminRegistrationsSearchPostRaw(requestParameters: ApiAdminRegistrationsSearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RegistrationStaffResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/Registrations/Search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PagingToJSON(requestParameters.paging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationStaffResultFromJSON(jsonValue));
    }

    /**
     */
    async apiAdminRegistrationsSearchPost(requestParameters: ApiAdminRegistrationsSearchPostRequest, initOverrides?: RequestInit): Promise<RegistrationStaffResult> {
        const response = await this.apiAdminRegistrationsSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAdminResetCacheGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Admin/ResetCache`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAdminResetCacheGet(initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiAdminResetCacheGetRaw(initOverrides);
        return await response.value();
    }

}
