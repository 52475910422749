import { Stack, Tooltip } from '@mui/material'
import React from 'react'
import { CountryStatistic } from '../../../../../dal'

interface RegistrationStackedBarGraphProps {
    statistic : CountryStatistic
}

export default function RegistrationStackedBarGraph(props: RegistrationStackedBarGraphProps) {

    const percentageComplete = `calc(${props.statistic.athletesWithCompleteRegistrations! / props.statistic.delegationSize! * 100}%)`
    const percentageIncomplete = `calc(${props.statistic.athletesWithIncompleteRegistrations! / props.statistic.delegationSize! * 100}%)`;
    const percentageMissing = `calc(${100 - (props.statistic.athletesWithCompleteRegistrations! / props.statistic.delegationSize! * 100) - (props.statistic.athletesWithIncompleteRegistrations! / props.statistic.totalNumberOfAthletes! * 100) }%)`;
    return (
        <div style={{width:'220px'}}>
            <Stack direction={'row'}>
                <Tooltip title='Complete'>
                    <div style={{ height: '10px', backgroundColor: '#dbffd1', width:percentageComplete, borderRadius:'10px 0px 0px 10px' }}></div>
                </Tooltip>
                <Tooltip title='Incomplete'>
                    <div style={{ height: '10px', backgroundColor: '#fdffbf', width:percentageIncomplete }}></div>
                </Tooltip>
                <Tooltip title='Missing'>
                    <div style={{ height: '10px', backgroundColor: '#fe6269', width:percentageMissing, borderRadius:'0px 10px 10px 0px' }}></div>
                </Tooltip>
            </Stack>
        </div>
    )
}
