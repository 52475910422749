import { DataGrid, getGridStringOperators, GridCallbackDetails, GridColDef, GridColTypeDef, GridFilterItem, GridFilterModel, GridRenderCellParams, GridRowModel, GridSortModel } from '@mui/x-data-grid';
import React, { useContext, useState } from 'react'
import { StaffContext } from '../../../context/StaffContext';
import moment, { Moment } from 'moment';
import { useDemoData } from '@mui/x-data-grid-generator';
import { MainPageContext } from '../../../context/MainPageContext';
import RegistrationsActionMenu from './RegistrationsActionMenu/RegistrationsActionMenu';
import { GetRegistrationLabelFromShort } from '../../../utils/RegistrationMapper';
import RegistrationTypeFilterSelector from './RegistrationTypeFilterSelector/RegistrationTypeFilterSelector';
import {v4 as uuidv4} from 'uuid';

interface RowsState {
    page: number;
    //pageSize: number;
    rows: GridRowModel[];
    loading: boolean;
}

export default function RegistrationsList() {

    const offset = (new Date()).getTimezoneOffset();

    const context = useContext(StaffContext);
    const { searchTerm, selectedDate } = useContext(MainPageContext);

    const loadServerRows = (page: number, pageSize: number, allRows: GridRowModel[]) =>
        new Promise<GridRowModel[]>(async (resolve) => {
            let pagedData = await context.getRegistrations({ size: pageSize, skip: pageSize * page, orderBy: orderBy, orderByDesc: orderByDesc });
            resolve(pagedData!);
        });

    const searchServerRows = (page: number, pageSize: number, allRows: GridRowModel[]) =>
        new Promise<GridRowModel[]>(async (resolve) => {
            let pagedData = await context.searchRegistrations({ size: pageSize, skip: pageSize * page, filterByValue: searchTerm, orderBy: orderBy, orderByDesc: orderByDesc });
            resolve(pagedData!);
        });

    const { data } = useDemoData({
        dataSet: 'Commodity',
        rowLength: 100,
        maxColumns: 6,
    });

    const [rowsState, setRowsState] = React.useState<RowsState>({
        page: 0,
        //pageSize: 5,
        rows: [],
        loading: false,
    });

    const [orderBy, setOrderBy] = useState("");
    const [orderByDesc, setOrderByDesc] = useState(true);

    const calculatePageSize = (): number => {
        return Math.ceil(((window.innerHeight - 56 - 60)) / 62)
    }

    const [pageSize, setPageSize] = useState(calculatePageSize());

    const renderDateColumn = (m: Moment, second?: boolean) => {
        if (m.year() === 1) {
            if (!second)
                return <div>No registrations</div>
            else
                return <div></div>
        }
        else {
            return <div>{m.format("DD/MM/YYYY")}</div>
        }
    }

    const renderActionColumn = (params: GridRenderCellParams) => {
        return <RegistrationsActionMenu row={params.row}></RegistrationsActionMenu>
    }

    const registrationTypeColumnType: GridColTypeDef = {
        extendType: 'string',
        filterOperators: getGridStringOperators()
          .filter((operator) => operator.value === 'equals' || operator.value === 'contains')
          .map((operator) => {
            let x = {
              ...operator,
              InputComponentProps: {
              },
            }

            x.InputComponent = RegistrationTypeFilterSelector;

            x.getApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef): any => {
                if (filterItem.value === undefined) {
                  return true;
                }

                context.setFilterFieldName('RegistrationType');
                context.setFilterFieldValue(filterItem.value);

                return true;
    
              }
            return x;
          })
      };

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Athlete name', width: 250, filterable: false },
        { field: 'actions', headerName: 'Actions', renderCell: (params) => { return renderActionColumn(params) }, sortable: false, filterable: false },
        { field: 'gender', headerName: 'Gender', width: 100, filterable: false },
        { field: 'country', headerName: 'Country', width: 150, filterable: false },
        { field: 'accreditationNumber', headerName: 'Accreditation number', width: 150, filterable: false },
        { field: 'sport', headerName: 'Sport', width: 150, filterable: false },
        { field: 'registrationType', 'type' : 'registrationType', headerName: 'Registration type', width: 190, renderCell: (params) => { return <div>{GetRegistrationLabelFromShort(params.row.registrationType)}</div> } , sortable: false},
        { field: 'badgeActivation', headerName: 'Badge Activation', width: 150, renderCell: (params) => {
            console.log(moment(new Date(params.row.badgeActivationDate)).add(-offset, 'minutes').format("DD/MM/YYYY"));
            return <div>{
                (new Date(params.row.badgeActivationDate)).getFullYear() > 2000 &&
                    moment(new Date(params.row.badgeActivationDate)).add(-offset, 'minutes').format("DD/MM/YYYY")}
                </div>}, filterable: false },
        { field: 'fromDate', headerName: 'From date', width: 150, renderCell: (params) => { return renderDateColumn(moment(params.row.fromDate)) }, filterable: false },
        { field: 'toDate', headerName: 'To date', width: 150, renderCell: (params) => { return renderDateColumn(moment(params.row.toDate), true) }, filterable: false },
        { field: 'city', headerName: 'City', width: 150, sortable: false, filterable: false },
        { field: 'address', headerName: 'Address', width: 150, sortable: false, filterable: false },
        { field: 'buildingName', headerName: 'Building name', width: 220, sortable: false, filterable: false, resizable: true },
        { field: 'floor', headerName: 'Floor', width: 100, sortable: false, filterable: false },
        { field: 'room', headerName: 'Room', width: 100, sortable: false, filterable: false },
        { field: 'postalCode', headerName: 'Postal code', width: 150, sortable: false, filterable: false },
        { field: 'comments', headerName: 'Comments', width: 150, sortable: false, filterable: false },
        { field: 'registrationId', headerName: 'Registration Id', width: 150, sortable: false, filterable: false },
    ];

    function debounce<Params extends any[]>(
        func: (...args: Params) => any,
        timeout: number,
    ): (...args: Params) => void {
        let timer: NodeJS.Timeout
        return (...args: Params) => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func(...args)
            }, timeout)
        }
    }

    React.useEffect(() => {
        let active = true;

        (async () => {

            setRowsState((prev) => ({ ...prev, loading: true }));

            console.log(pageSize);

            if (searchTerm.length < 3) {
                const newRows = await loadServerRows(
                    rowsState.page,
                    pageSize > 0 ? pageSize : 5,
                    data.rows,
                );

                if (!active) {
                    return;
                }

                setRowsState((prev) => ({ ...prev, loading: false, rows: newRows }));
            }
            else {
                const newRows = await searchServerRows(
                    rowsState.page,
                    pageSize > 0 ? pageSize : 5,
                    data.rows,
                );

                if (!active) {
                    return;
                }

                setRowsState((prev) => ({ ...prev, loading: false, rows: newRows }));
            }
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line
    }, [rowsState.page, pageSize, searchTerm, orderByDesc, orderBy, selectedDate, context.filterFieldValue]);


    React.useEffect(() => {

        function handleResize() {
            setPageSize(calculatePageSize());
        }

        window.addEventListener('resize', debounce(handleResize, 700));
    }, [])

    const handleSortingChanged = (model: GridSortModel, details: GridCallbackDetails) => {
        if (model.length > 0) {
            console.log(model[0].field);
            setOrderBy(model[0].field);
            if (model[0].sort === 'asc') {
                setOrderByDesc(false);
            }
            else {
                setOrderByDesc(true);
            }
        }
        else {
            setOrderBy("");
            setOrderByDesc(true);
        }
    }

    const handleFilterChanged = (model: GridFilterModel, details: GridCallbackDetails) => {
        if(model.items.length === 0){
            context.setFilterFieldName("");
            context.setFilterFieldValue("");
        }
    }

    return (
        <div style={{ height: "90vh", padding: "4px 4px 4px 4px" }}>
            <DataGrid
                columns={columns}
                sortingMode='server'
                pagination
                rowCount={context.registrationsCount}
                pageSize={pageSize}
                onSortModelChange={handleSortingChanged}
                onFilterModelChange={handleFilterChanged}
                //rowsPerPageOptions={[5, 10, 20, 30]}
                {...rowsState}
                paginationMode="server"
                onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
                onPageSizeChange={(pageSize) => {
                    console.log("Page size change");
                }
                }
                getRowId={(r: any) => uuidv4()}
                columnTypes={{ registrationType: registrationTypeColumnType }}
            />
        </div>
    );
}

