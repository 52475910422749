import React, { useContext, useRef } from 'react'

import { IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { RegistrationStaffInfo } from '../../../../dal';
import moment from 'moment';
import { GetRegistrationLabelFromShort } from '../../../../utils/RegistrationMapper';
import { StaffContext } from '../../../../context/StaffContext';
import { useNavigate } from 'react-router-dom';

interface RegistrationsActionMenuProps {
    row: RegistrationStaffInfo
}

export default function RegistrationsActionMenu(props: RegistrationsActionMenuProps) {


    const context = useContext(StaffContext);

    let navigate = useNavigate();

    const textAreaRef = useRef<any>(null);

    // eslint-disable-next-line
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    function copyToClipboard(event: React.MouseEvent<HTMLButtonElement>) {
        function listener(e: any) {
            let str = textAreaRef.current.innerHTML;
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);

        setAnchorEl(event.currentTarget);

        const delayDebounceFn = setTimeout(() => {
            setAnchorEl(null);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }

    const handleNavigateSchedule = () => {
        navigate("/events/" + props.row.accreditationNumber )
    }

    return (
        <div>
            <Tooltip title="Copy registration data to clipboard">
                <IconButton onClick={copyToClipboard} color="primary" aria-label="upload picture" component="span" size='small'>
                    <ContentCopyIcon fontSize='inherit' />
                </IconButton>
            </Tooltip>
            <Tooltip title="Generate PDF for registration">
                <IconButton onClick={() => context.exportRegistrationsForAthlete(props.row)} color="primary" aria-label="upload picture" component="span" size='small'>
                    <FileDownloadIcon fontSize='inherit' />
                </IconButton>
            </Tooltip>
            <Tooltip title="See schedule for athlete">
                <IconButton onClick={() => handleNavigateSchedule()} color="primary" aria-label="upload picture" component="span" size='small'>
                    <EventNoteIcon fontSize='inherit' />
                </IconButton>
            </Tooltip>
            <div hidden ref={textAreaRef}>
                <p style={{ fontSize: "20px" }}>{props.row.name}</p>
                <p>Accreditation number: {props.row.accreditationNumber}</p>
                <p>Country: {props.row.country}</p>
                <p>Gender: {props.row.gender}</p>
                <p>Discipline: {props.row.discipline}</p>
                <p>Registration type: {GetRegistrationLabelFromShort(props.row.registrationType!)}</p>
                <p>
                    From date:{" "}
                    {props.row.fromDate
                        ? moment(props.row.fromDate).format("MM/DD/yyyy")
                        : ""}
                </p>
                <p>
                    To date:{" "}
                    {props.row.toDate
                        ? moment(props.row.toDate).format("MM/DD/yyyy")
                        : ""}
                </p>
                <p>City: {props.row.city}</p>
                <p>Address: {props.row.address}</p>
                <p>Postal code: {props.row.postalCode}</p>
                <p>Building name: {props.row.buildingName}</p>
                <p>Floor: {props.row.floor}</p>
                <p>Room number: {props.row.room}</p>
                <p>Comments: {props.row.comments}</p>
            </div>
        </div>
    )
}
