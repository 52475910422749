import React from 'react';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { MemoizedMainPage } from './components/MainPage/MainPage';
import LoginPage from './components/LoginPage/LoginPage';
import { ThemeProvider } from '@mui/system';
import { theme } from './theme/MainTheme';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { reactPlugin } from './utils/AppInsights';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

function App() {

  console.log("app init");

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrowserRouter>
          <div className="App">
            <AuthenticatedTemplate>
              <MemoizedMainPage></MemoizedMainPage>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <LoginPage></LoginPage>
            </UnauthenticatedTemplate>
          </div>
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </ThemeProvider>
  );
}

export default App;

