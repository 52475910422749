import {Stack, styled } from '@mui/material'
import React from 'react'
import { Registration } from '../../../../dal'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
interface AthleteRegistrationStatusIndicatorProps {
    registrations: Registration[];
}

export default function AthleteRegistrationStatusIndicator(props: AthleteRegistrationStatusIndicatorProps) {


    const Indicator = styled('div')(({ theme }) => ({
        display: 'flex', 
        height: '2rem',
        backgroundColor: '#e3e3e3', 
        padding: '7px 7px 7px 7px', 
        borderRadius: '8px', 
        //color: 'white'
    }));

    return (
        <Indicator>
            {
                (props.registrations.filter(r => r.isComplete === false).length === 0) && (props.registrations.length > 0) &&
                <Stack spacing={1} direction={'row'} alignItems={'center'}>
                <CheckCircleOutlineIcon sx={{color: '#dbffd1'}} />
                <div>Complete</div>
                </Stack>
            }
            {
                (props.registrations.length === 0) &&
                <Stack  spacing={1} direction={'row'} alignItems={'center'}>
                    <ErrorOutlineIcon sx={{color: '#fe6269'}} />
                    <div>Missing</div>
                </Stack>
            }
            {
                (props.registrations.filter(r => r.isComplete === false).length > 0) &&
                <Stack  spacing={1} direction={'row'} alignItems={'center'}>
                <WarningAmberIcon sx={{color: '#fdffbf'}} />
                <div>Incomplete</div>
                </Stack>
                }
               
        </Indicator>
    )
}
