import { createTheme, ThemeOptions } from "@mui/material";
import "../resources/styles/fonts.css"
import background_image from "../resources/images/ITA_background_13.jpg"
import { colorHighlight } from "../config/config";

export const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        }, 
        info: {
            main: '#e3e3e3'
        }
        
    },
    typography: {
        fontFamily: 'Barlow',
        h1: {
            fontFamily: 'Century Gothic',
        },
        h5:{
            fontFamily: 'Barlow', 
            fontWeight: 700,
            color: '#00205B',
            fontSize: '1rem'
        }, 
        h4:{
            fontFamily: 'Barlow', 
            fontWeight: 700,
            color: '#00205B',
            fontSize: '1.4rem'
        }
    },
    components:{
        MuiDrawer:{
            styleOverrides:{
                paper: {
                    backgroundColor: '#41BEAA'
                }
            }
        }, 
        MuiContainer:{
            styleOverrides: {
                root:{
                    padding:'0px 0px 0px 0px',
                    backgroundColor: 'white'

                }
            }
        }, 
        MuiCssBaseline: {
            styleOverrides:{
                body:{
                    backgroundImage: `url(${background_image})`,
                    backgroundRepeat:'no-repeat',
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    
                }
            }            
        }, 
        MuiAppBar: {
            styleOverrides: {
                colorPrimary : {
                    backgroundColor : colorHighlight,
                }
            }
        }
    }
    
};

export const theme = createTheme(themeOptions);
