import { MsalContext } from "@azure/msal-react";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import React, { useContext, useEffect, useState } from 'react'
import { AdminApi, ApiAdminAthletesPostRequest, ApiAdminAthletesSearchPostRequest, Athlete, CountryDisciplineTeamEvent, CountryStatistic, EventApi, IndividualEvent, RoomingUser, TeamEvent } from "../dal"
import { apiScopes, apiAuthority, backendBasePath } from "../config/config";
import { Configuration } from '../dal/index';
import { MainPageContext } from "./MainPageContext";


type AdminContextType = {
    athletes: Athlete[] | undefined
    athleteCount: number
    refreshAthletes: (paging: ApiAdminAthletesPostRequest) => void
    refreshAthleteSearch: (paging: ApiAdminAthletesSearchPostRequest) => void
    countryStatistics: CountryStatistic[] | undefined
    refreshCountryStatistics: () => void
    selectedAthlete: Athlete | undefined
    refreshSelectedAthlete: (accreditationNumber: string) => void
    individualEvents: IndividualEvent[]
    teamEvents: TeamEvent[]
    orderRegistrationsBy: string
    setOrderRegistrationsBy: (s: string) => void
    sendNotification: (message: string, countryName: string, recipients: string) => void
    getNocMember: (countryName: string) => Promise<RoomingUser[]> | null,
    getCDTeamEvents: (accreditationNumber: string) => Promise<CountryDisciplineTeamEvent[]> | null;


}


export const AdminContext = React.createContext<AdminContextType>({
    athletes: [],
    athleteCount: 0,
    refreshAthletes: (paging: ApiAdminAthletesPostRequest) => null,
    refreshAthleteSearch: (paging: ApiAdminAthletesSearchPostRequest) => null,
    countryStatistics: [],
    refreshCountryStatistics: () => null,
    selectedAthlete: undefined,
    refreshSelectedAthlete: (accreditationNumber: string) => null,
    individualEvents: [],
    teamEvents: [],
    orderRegistrationsBy: "",
    setOrderRegistrationsBy: (s: string) => null,
    sendNotification: (message: string, countryName: string, recipients: string) => null,
    getNocMember: (countryName: string) => null,
    getCDTeamEvents: (accreditationNumber: string) => null
})


export function useAdminContext() {

    const msalContext = useContext(MsalContext);

    const [athletes, setAthletes] = useState<Athlete[]>([]);

    const [countryStatistics, setCountryStatistics] = useState<CountryStatistic[]>([])

    const [selectedAthlete, setSelectedAthlete] = useState<Athlete>();

    const [individualEvents, setIndividualEvents] = useState<IndividualEvent[]>([])

    const [teamEvents, setTeamEvents] = useState<TeamEvent[]>([]);

    const [orderRegistrationsBy, setOrderRegistrationsBy] = useState("");

    const [athleteCount, setAthleteCount] = useState(0);

    const context = useContext(MainPageContext);


    const refreshSelectedAthlete = async (accreditationNumber: string) => {

        let token = await silentRequest();

        let adminApi = new AdminApi(token);

        let response = await adminApi.apiAdminAthleteIdPost({ id: accreditationNumber });

        setSelectedAthlete(response);

        let eventApi = new EventApi(token);

        let events = await eventApi.apiEventAthleteGet({ accreditationNumber: accreditationNumber });

        let teamEvents = await eventApi.apiEventTeamGet({ accreditationNumber: accreditationNumber });

        setIndividualEvents(events!);
        setTeamEvents(teamEvents!);

    }


    useEffect(() => {
        if (context.searchTerm.length > 3) {
            silentRequest().then(c => {
                let adminApi = new AdminApi(c);
                adminApi.apiAdminAthletesSearchPost({ searchPaging: { searchTerm: context.searchTerm, size: 20, skip: 0 } }).then(r => {
                    if (r.athletes) setAthletes(r.athletes!);
                    if (r.count) setAthleteCount(r.count);
                }).catch(e => console.log(e));
            })
        }
        return () => {
            console.log('cleanup')
        }
        // eslint-disable-next-line
    }, [context.searchTerm])

    const refreshAthleteSearch = (paging: ApiAdminAthletesSearchPostRequest) => {

        silentRequest().then(c => {
            let adminApi = new AdminApi(c);
            adminApi.apiAdminAthletesSearchPost(paging).then(r => {
                if (r.athletes) setAthletes(r.athletes);
                if (r.count) setAthleteCount(r.count);
            }).catch(e => console.log(e));
        })

    }

    const silentRequest = () => {
        if (msalContext.accounts[0]) {
            const accessTokenRequest: SilentRequest = {
                scopes: apiScopes,
                account: msalContext.accounts[0] as AccountInfo,
                authority: apiAuthority,
            } as SilentRequest;

            return msalContext.instance
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    const accessToken = response.accessToken;
                    let c: Configuration = new Configuration({
                        accessToken: accessToken,
                        basePath: backendBasePath,
                    });
                    return c;
                });
        }
        return Promise.reject();
    };

    const sendNotification = async (message: string, countryName: string, recipients: string) => {
        let token = await silentRequest();
        let adminApi = new AdminApi(token);

        await adminApi.apiAdminNotificationPost({ countryName: countryName, message: message, recipients: recipients });

    }

    const getNocMember = async (countryName: string): Promise<RoomingUser[]> => {
        let token = await silentRequest();
        let adminApi = new AdminApi(token);

        let result = await adminApi.apiAdminCountriesMembersPost({ countryName: countryName });

        return result;

    }

    const getCDTeamEvents = async (accreditationNumber: string): Promise<CountryDisciplineTeamEvent[]> => {

        let token = await silentRequest();
        let eventApi = new EventApi(token);

        let result = eventApi.apiEventCdGet({ accreditationNumber: accreditationNumber });

        return result;

    }

    const refreshAthletes = (paging: ApiAdminAthletesPostRequest) => {

        console.log('refreshing athletes');

        refreshAthleteCount();

        silentRequest().then(c => {
            let adminApi = new AdminApi(c);
            adminApi.apiAdminAthletesPost(paging).then(r => {
                setAthletes(r);
            });

        })
    }

    const refreshAthleteCount = () => {
        silentRequest().then(c => {
            let adminApi = new AdminApi(c);
            adminApi.apiAdminAthletesGetCountPost().then(r => {
                setAthleteCount(r);
            })
        })
    }


    const refreshCountryStatistics = async () => {

        try {
            let token = await silentRequest();
            let adminApi = new AdminApi(token);
            let statistics = await adminApi.apiAdminCountriesStatisticsPost({ paging: { size: 1000, skip: 0 } });
            setCountryStatistics(statistics);
        }
        catch (e) {
            console.log('Error errror errrorr')
            console.log(e);
        }
    }


    return {
        athletes,
        refreshAthletes,
        athleteCount,
        refreshAthleteSearch,
        countryStatistics,
        refreshCountryStatistics,
        selectedAthlete,
        refreshSelectedAthlete,
        individualEvents,
        teamEvents,
        orderRegistrationsBy,
        setOrderRegistrationsBy,
        sendNotification,
        getNocMember,
        getCDTeamEvents
    }


}