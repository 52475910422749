/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Registration,
    RegistrationFromJSON,
    RegistrationToJSON,
} from '../models';

export interface ApiRegistrationIdDeleteRequest {
    id: number;
}

export interface ApiRegistrationIdGetRequest {
    id: number;
}

export interface ApiRegistrationIdPutRequest {
    id: string;
    registration?: Registration;
}

export interface ApiRegistrationPostRequest {
    registration?: Registration;
}

/**
 * 
 */
export class RegistrationApi extends runtime.BaseAPI {

    /**
     */
    async apiRegistrationDownloadGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registration/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiRegistrationDownloadGet(initOverrides?: RequestInit): Promise<object> {
        const response = await this.apiRegistrationDownloadGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Registration>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegistrationFromJSON));
    }

    /**
     */
    async apiRegistrationGet(initOverrides?: RequestInit): Promise<Array<Registration>> {
        const response = await this.apiRegistrationGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationIdDeleteRaw(requestParameters: ApiRegistrationIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistrationIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationIdDelete(requestParameters: ApiRegistrationIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRegistrationIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRegistrationIdGetRaw(requestParameters: ApiRegistrationIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Registration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistrationIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationIdGet(requestParameters: ApiRegistrationIdGetRequest, initOverrides?: RequestInit): Promise<Registration> {
        const response = await this.apiRegistrationIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistrationIdPutRaw(requestParameters: ApiRegistrationIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistrationIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationToJSON(requestParameters.registration),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistrationIdPut(requestParameters: ApiRegistrationIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRegistrationIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRegistrationPostRaw(requestParameters: ApiRegistrationPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Registration>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistrationToJSON(requestParameters.registration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistrationPost(requestParameters: ApiRegistrationPostRequest, initOverrides?: RequestInit): Promise<Registration> {
        const response = await this.apiRegistrationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
