/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Building,
    BuildingFromJSON,
    BuildingToJSON,
} from '../models';

export interface ApiBuildingIdDeleteRequest {
    id: number;
}

export interface ApiBuildingIdGetRequest {
    id: number;
}

export interface ApiBuildingIdPutRequest {
    id: number;
    building?: Building;
}

export interface ApiBuildingPostRequest {
    building?: Building;
}

/**
 * 
 */
export class BuildingApi extends runtime.BaseAPI {

    /**
     */
    async apiBuildingGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Building>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Building`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BuildingFromJSON));
    }

    /**
     */
    async apiBuildingGet(initOverrides?: RequestInit): Promise<Array<Building>> {
        const response = await this.apiBuildingGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBuildingIdDeleteRaw(requestParameters: ApiBuildingIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBuildingIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Building/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBuildingIdDelete(requestParameters: ApiBuildingIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiBuildingIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBuildingIdGetRaw(requestParameters: ApiBuildingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Building>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBuildingIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Building/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingFromJSON(jsonValue));
    }

    /**
     */
    async apiBuildingIdGet(requestParameters: ApiBuildingIdGetRequest, initOverrides?: RequestInit): Promise<Building> {
        const response = await this.apiBuildingIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBuildingIdPutRaw(requestParameters: ApiBuildingIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBuildingIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Building/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingToJSON(requestParameters.building),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBuildingIdPut(requestParameters: ApiBuildingIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiBuildingIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBuildingPostRaw(requestParameters: ApiBuildingPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Building`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingToJSON(requestParameters.building),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBuildingPost(requestParameters: ApiBuildingPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiBuildingPostRaw(requestParameters, initOverrides);
    }

}
