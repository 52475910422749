import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { cookieText1, cookieText2, cookieText3 } from '../../../resources/text/text'

interface CookiePolicyProps {
    show: boolean;
    handleClose: () => void;
}

export default function CookiePolicy(props: CookiePolicyProps) {
    return (
        <Dialog open={props.show} onClose={props.handleClose}>
            <DialogTitle>Our cookie policy</DialogTitle>
            <DialogContent>
                <DialogContentText>

                    {cookieText1} <br /><br />
                    {cookieText2} <br /><br />
                    {cookieText3} <br /><br />

                </DialogContentText>
                <DialogActions>
                    <Button type="submit" onClick={() => { props.handleClose() }}>Close</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
