import { Button, Paper } from '@mui/material'
import { GridRowId } from '@mui/x-data-grid';

import React, { useState, useContext } from 'react'
import { MainPageContext } from '../../../../context/MainPageContext';
import AddRegistrationDialog from '../../AddRegistrationDialog/AddRegistrationDialog';

import styles from './AthleteToolBar.module.css'

interface AthleteToolBarProps {
    selectedRows: GridRowId[];
}




export default function AthleteToolBar(props: AthleteToolBarProps) {

    const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);
    const context = useContext(MainPageContext);

    const handleCloseAddDialog = () => {
        
        setShowRegistrationDialog(false);
        
        setTimeout(() => {
            context.refreshAll();
        }, 200);
    }

    return (
        <React.Fragment>
            <Paper style={{ padding: "15px 20px 15px 20px", marginTop: "10px", marginBottom: "10px", backgroundColor: "#00A9E6", color: "#e6e6e6" }} elevation={3} >
                <div className={styles.athleteToolbarContainer}>
                    {props.selectedRows.length} athletes selected
                    <Button variant="contained" onClick={() => {setShowRegistrationDialog(true)}} >Create bulk registration</Button>
                </div>
            </Paper>
            <AddRegistrationDialog selectedAthletes={props.selectedRows} show={showRegistrationDialog} handleClose={handleCloseAddDialog} selectedRegistration={null}></AddRegistrationDialog>
        </React.Fragment>
    )
}
