/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildingAlias,
    BuildingAliasFromJSON,
    BuildingAliasFromJSONTyped,
    BuildingAliasToJSON,
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './';

/**
 * 
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    buildingId?: number;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    buildingLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    district?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    additionalComments?: string | null;
    /**
     * 
     * @type {Array<BuildingAlias>}
     * @memberof Building
     */
    buildingAliases?: Array<BuildingAlias> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    deleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Building
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Building
     */
    modified?: Date;
    /**
     * 
     * @type {Event}
     * @memberof Building
     */
    event?: Event;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    modifiedBy?: string | null;
}

export function BuildingFromJSON(json: any): Building {
    return BuildingFromJSONTyped(json, false);
}

export function BuildingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Building {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingId': !exists(json, 'buildingId') ? undefined : json['buildingId'],
        'buildingLocation': !exists(json, 'buildingLocation') ? undefined : json['buildingLocation'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'district': !exists(json, 'district') ? undefined : json['district'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'additionalComments': !exists(json, 'additionalComments') ? undefined : json['additionalComments'],
        'buildingAliases': !exists(json, 'buildingAliases') ? undefined : (json['buildingAliases'] === null ? null : (json['buildingAliases'] as Array<any>).map(BuildingAliasFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'event': !exists(json, 'event') ? undefined : EventFromJSON(json['event']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
    };
}

export function BuildingToJSON(value?: Building | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingId': value.buildingId,
        'buildingLocation': value.buildingLocation,
        'buildingName': value.buildingName,
        'address': value.address,
        'city': value.city,
        'district': value.district,
        'postalCode': value.postalCode,
        'additionalComments': value.additionalComments,
        'buildingAliases': value.buildingAliases === undefined ? undefined : (value.buildingAliases === null ? null : (value.buildingAliases as Array<any>).map(BuildingAliasToJSON)),
        'deleted': value.deleted,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'event': EventToJSON(value.event),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
    };
}

