import { DatePicker } from '@mui/lab'
import { DatePickerProps, fieldToDatePicker } from 'formik-mui-lab';
import moment from 'moment';

import React from 'react'

export default function RegistrationDatePicker(props: DatePickerProps) {

  moment.tz.setDefault('GMT')
  const offset = (new Date()).getTimezoneOffset();

  return (
      <DatePicker minDate={moment("2022/01/27").add(-offset, 'minutes')} maxDate={moment("2022/02/22").add(-offset, 'minutes')} {...fieldToDatePicker(props)}></DatePicker>
  )
}
