import { Paper } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useContext, useState } from 'react'
import { AdminContext } from '../../../context/AdminContext';
import { v4 as uuidv4 } from 'uuid';
import AdminCountryActionField from './AdminCountryActionField/AdminCountryActionField';
import RegistrationStackedBarGraph from './AdminCountryListRow/RegistrationStackedBarGraph/RegistrationStackedBarGraph';

export default function AdminCountryList() {

    const calculatePageSize = (): number => {
        return Math.ceil(((window.innerHeight - 220)) / 54)
    }

    const context = useContext(AdminContext);

    const [pageSize, setPageSize] = useState(calculatePageSize());

    function debounce<Params extends any[]>(
        func: (...args: Params) => any,
        timeout: number,
    ): (...args: Params) => void {
        let timer: NodeJS.Timeout
        return (...args: Params) => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func(...args)
            }, timeout)
        }
    }

    React.useEffect(() => {
        context.refreshCountryStatistics();
        // eslint-disable-next-line
    }, []);


    React.useEffect(() => {

        function handleResize() {
            setPageSize(calculatePageSize());
        }

        window.addEventListener('resize', debounce(handleResize, 700));

        // eslint-disable-next-line
    }, [pageSize])



    const columns: GridColDef[] = [
        { field: 'countryName', headerName: 'Country Name', width: 120 }, 
        { field: 'delegationSize', headerName: 'Delegation size', width: 120, filterable: false },
        { field: 'totalNumberOfAthletes', headerName: 'Long list', width: 120, filterable: false },
        { field: 'totalNumberOfRegistrations', headerName: 'Total registrations', width: 120, filterable: false },
        { field: 'athletesWithCompleteRegistrations', headerName: 'Athlete with complete registrations', width: 120, filterable: false },
        { field: 'athletesWithIncompleteRegistrations', headerName: 'Athlete with incomplete registrations', width: 120, filterable: false },
        { field: 'athletesWithNoRegistrations', headerName: 'Athlete no registrations', width: 120, filterable: false },
        { field: 'activeBadgeCount', headerName: 'Activated badges', width: 120, filterable: false },
        { field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => { return <AdminCountryActionField row={params.row} />  }, filterable: false, sortable: false },
        { field: 'graph', headerName: 'Graph', width: 220, renderCell: (params) => { return <RegistrationStackedBarGraph statistic={params.row} />  }, filterable: false, sortable: false },
    ];

    return (
        <Paper sx={{ marginBottom: '20px', height: "90vh" }}>
            <DataGrid
                checkboxSelection
                disableSelectionOnClick
                disableDensitySelector
                pageSize={100}
                rows={context.countryStatistics!.filter(c => (c.delegationSize! > 0 || c.totalNumberOfRegistrations!> 0))}
                columns={columns}
                getRowId={(r: any) => uuidv4()}
            />
        </Paper>

    )
}
