import { Box, Button, Chip, Stack, styled, Typography } from '@mui/material'
import moment from 'moment';
import React, { useContext, useState } from 'react'
import { AthleteDetailsContext } from '../../../../context/AthleteDetailsContext';
import { MainPageContext } from '../../../../context/MainPageContext';
import { Registration } from '../../../../dal'
import { GetRegistrationLabel } from '../../../../utils/RegistrationMapper';
import AddRegistrationDialog from '../../AddRegistrationDialog/AddRegistrationDialog';
import RegistrationCompletenessIndicator from './RegistrationCompletenessIndicator/RegistrationCompletenessIndicator';
import RegistrationDeleteConfirmation from './RegistrationDeleteConfirmation/RegistrationDeleteConfirmation';

interface RegistrationRowProps {
    registration: Registration;
}

export default function RegistrationRow(props: RegistrationRowProps) {


    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const { getAthlete } = useContext(AthleteDetailsContext);

    const context = useContext(MainPageContext);

    const handleFinishUpdate = () => {
        setTimeout(() => {
            getAthlete(props.registration.athleteId!);
            context.refreshAll();
        }, 400);


        setShowEditDialog(false);
    }

    const RegistrationCard = styled(Box)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        border: '1px dashed black', 
        marginTop: '10px'
    }));

    return (
        <RegistrationCard>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent={'space-between'}>
                <div>
                    <Typography variant={'h4'}>{props.registration.buildingName}</Typography>
                </div>
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                    <Chip label={GetRegistrationLabel(props.registration!.registrationType!)} />
                    <RegistrationCompletenessIndicator registration={props.registration} />
                </Stack>
                <Stack direction={'column'} spacing={2}>
                    <div>
                        <Typography> From: {moment(props.registration.fromDate).format('DD MMMM yyyy')} </Typography>
                    </div>
                    <div>
                        <Typography> To: {moment(props.registration.toDate).format('DD MMMM yyyy')} </Typography>
                    </div>
                </Stack>
                <Stack direction={'column'} spacing={2} justifyContent={'flex-start'}>

                    {props.registration.city &&
                        <div>
                            <Typography> {props.registration.city} </Typography>
                        </div>
                    }
                    {props.registration.streetAddress &&
                        <div hidden={props.registration.streetAddress === ''}>
                            <Typography> {props.registration.streetAddress} </Typography>
                        </div>
                    }
                    {props.registration.postalCode &&
                        <div hidden={props.registration.postalCode === ''}>
                            <Typography> {props.registration.postalCode} </Typography>
                        </div>
                    }
                    {props.registration.roomNumber &&
                        <div hidden={props.registration.roomNumber === ''} >
                            <Typography> Room: {props.registration.roomNumber} </Typography>
                        </div>
                    }
                    {props.registration.additionalComments !== '' &&
                        <div>
                            <Typography> {props.registration.additionalComments} </Typography>
                        </div>
                    }
                </Stack>
                <Stack spacing={3} direction={{ xs: 'row', sm: 'column' }} justifyContent={'space-between'}>
                    <div><Button onClick={() => setShowEditDialog(true)}>Edit</Button>
                    </div>
                    
                    <div><Button color={'warning'} onClick={() => setShowDeleteDialog(true)}>Delete</Button>
                    </div>
                </Stack>
            </Stack>

            <RegistrationDeleteConfirmation
                show={showDeleteDialog}
                registrationId={props.registration.registrationId!}
                handleClose={() => setShowDeleteDialog(false)}
            />
            <AddRegistrationDialog selectedRegistration={props.registration} show={showEditDialog} handleClose={() => handleFinishUpdate()} selectedAthletes={[]}></AddRegistrationDialog>
        </RegistrationCard>
    )
}
