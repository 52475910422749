import { RoomingUser } from "../dal";



export const GetUserRole = (user : RoomingUser) : string | null => { 

    if(user.roles!.filter(r => r.roleName === 'RoomingAdmin').length > 0)
        return 'RoomingAdmin'
    if(user.roles!.filter(r => r.roleName === 'RoomingStaff').length > 0)
        return 'RoomingStaff'

    return null;

}