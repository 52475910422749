import React, { useContext, useEffect, useState } from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { AdminContext } from '../../../context/AdminContext';
import { TablePagination } from '@mui/material';
import AdminAthleteListRow from './AdminAthleteListRow/AdminAthleteListRow';
import { MainPageContext } from '../../../context/MainPageContext';
import { AthleteDetailsContext, useAthleteDetailsContext } from '../../../context/AthleteDetailsContext';

export default function AdminAthleteList() {


    function debounce<Params extends any[]>(
        func: (...args: Params) => any,
        timeout: number,
    ): (...args: Params) => void {
        let timer: NodeJS.Timeout
        return (...args: Params) => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                func(...args)
            }, timeout)
        }
    }

    const calculatePageSize = (): number => {
        return Math.ceil(((window.innerHeight - 185)) / 70)
    }

    const context = useContext(AdminContext);
    const mainContext = useContext(MainPageContext);

    const athleteDetailsContext = useAthleteDetailsContext();

    // eslint-disable-next-line
    const [rowsPerPage, setRowsPerPage] = useState(calculatePageSize());
    const [page, setPage] = useState(0);


    useEffect(() => {
        context.refreshAthletes({ paging: { size: rowsPerPage, skip: page * rowsPerPage } });

        function handleResize() {
            console.log('resize')
            setRowsPerPage(calculatePageSize());
        }

        window.addEventListener('resize', debounce(handleResize, 700));

        // eslint-disable-next-line
    }, [])


    const handleChangePage = (event: unknown | null, page: number) => {

        setPage(page)


    }

    useEffect(() => {
        context.refreshAthletes({
            paging: {
                size: rowsPerPage,
                skip: page * rowsPerPage
            }
        })
        // eslint-disable-next-line
    }, [rowsPerPage, page, athleteDetailsContext.isDirty, mainContext.isDirty])

    useEffect(() => {

        context.refreshAthleteSearch({ searchPaging: { searchTerm: mainContext.searchTerm, size: rowsPerPage, skip: page * rowsPerPage } })

        // eslint-disable-next-line
    }, [mainContext.searchTerm, page, mainContext.isDirty]);

    return (
        <AthleteDetailsContext.Provider value={athleteDetailsContext}>
            <Paper>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Accreditation number</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Country</TableCell>
                                <TableCell align="right">Discipline</TableCell>
                                <TableCell align="right">Sport</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                context.athletes!.map(athlete => {
                                    return (<AdminAthleteListRow athlete={athlete}></AdminAthleteListRow>)
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={context.athleteCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </AthleteDetailsContext.Provider>
    )
}
