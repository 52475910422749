import { TableRow, TableCell, Collapse, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { Athlete } from '../../../../dal'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RegistrationRow from '../../../MainPage/AtheleteDetailsPane/RegistrationRow/RegistrationRow';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AddRegistrationDialog from '../../../MainPage/AddRegistrationDialog/AddRegistrationDialog';
import { GridRowId } from '@mui/x-data-grid';

interface AdminAthleteListRowProps {
    athlete: Athlete
}

export default function AdminAthleteListRow(props: AdminAthleteListRowProps) {

    const [open, setOpen] = useState(false);

    const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);

    const handleCloseAddDialog = () => {
        setShowRegistrationDialog(false);
    }

    const handleShowAddDialog = () => {
        setShowRegistrationDialog(true);
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {props.athlete.accreditationNumber}
                </TableCell>
                <TableCell align="right">{props.athlete.fullName}</TableCell>
                <TableCell align="right">{props.athlete.country?.countryName}</TableCell>
                <TableCell align="right">{props.athlete.discipline?.disciplineName}</TableCell>
                <TableCell align="right">{props.athlete.discipline?.sportName}</TableCell>
                <TableCell align="right">
                    <Tooltip title="Create a new registration for the athlete">
                        <IconButton onClick={() => handleShowAddDialog()} color="primary" aria-label="upload picture" component="span" size='small'>
                            <AddCircleIcon fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="See athlete schedule">
                        <IconButton color="primary" aria-label="upload picture" component="span" size='small'>
                            <FeaturedPlayListIcon fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                    <AddRegistrationDialog selectedAthletes={[props.athlete.athleteId] as GridRowId[]} show={showRegistrationDialog} handleClose={handleCloseAddDialog} selectedRegistration={null}></AddRegistrationDialog>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit sx={{ padding: '10px 10px 10px 10px' }}>

                        {props.athlete.registrations!.length > 0 &&
                            props.athlete.registrations!.map(registration => {
                                return (<RegistrationRow registration={registration}></RegistrationRow>)
                            })
                        }

                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

