import React, { useContext, useState } from "react";
import { AdminApi, Paging } from "../dal";
import { apiScopes, apiAuthority, backendBasePath } from "../config/config";
import { Configuration } from '../dal/index';
import { MsalContext } from "@azure/msal-react";
import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import { RegistrationStaffInfo } from "../dal/models/RegistrationStaffInfo";
import { CreatePDF } from "../utils/CreatePdf";
import { MainPageContext } from "./MainPageContext";



type StaffContextType = {
    registrations: RegistrationStaffInfo[] | undefined;
    registrationsCount: number;
    registrationsLoading: boolean;
    getRegistrations: (paging: Paging) => void;
    searchRegistrations: (paging: Paging) => void;
    exportRegistrationsForAthlete: (registrationId: RegistrationStaffInfo) => void;
    filterFieldName: string;
    setFilterFieldName: (s:string) => void;
    filterFieldValue: string;
    setFilterFieldValue: (s: string) => void;
}

export const StaffContext = React.createContext<StaffContextType>({
    registrations: [],
    registrationsCount: 0,
    registrationsLoading: false,
    getRegistrations: (paging: Paging) => null,
    searchRegistrations: (paging: Paging) => null,
    exportRegistrationsForAthlete: (registrationId: RegistrationStaffInfo) => null,
    filterFieldName: "",
    setFilterFieldName: (s:string) => null,
    filterFieldValue: "",
    setFilterFieldValue: (s: string) => null
})

export function useStaffContext() {

    const msalContext = useContext(MsalContext);

    const [registrations, setRegistrations] = useState<RegistrationStaffInfo[]>([]);
    const [registrationsLoading, setRegistrationsLoading] = useState(false);

    const [registrationsCount, setRegistrationsCount] = useState(8000);

    const [filterFieldName, setFilterFieldName] = useState("");
    const [filterFieldValue, setFilterFieldValue] = useState("");

    const mainPageContext = useContext(MainPageContext);

    const searchRegistrations = async (paging: Paging): Promise<RegistrationStaffInfo[]> => {

        setRegistrationsLoading(true);

        let rPage = null;

        if (!mainPageContext.selectedDate) {
            let t = await silentRequest();
            let adminApi = new AdminApi(t);
            rPage = await adminApi.apiAdminRegistrationsSearchPost({ paging: paging })
        }
        else {
            let t = await silentRequest();
            let adminApi = new AdminApi(t);
            rPage = await adminApi.apiAdminRegistrationsSearchDatePost({ date: mainPageContext.selectedDate, paging: paging });
        }


        await setRegistrations(rPage.page!);
        await setRegistrationsCount(rPage.totalCount!);

        setRegistrationsLoading(false);

        return rPage.page!;
    }

    const getRegistrations = async (paging: Paging): Promise<RegistrationStaffInfo[]> => {

        setRegistrationsLoading(true);

        let t = await silentRequest();
        let adminApi = new AdminApi(t);
        let rPage = null;

        if (mainPageContext.selectedDate) {
            if(filterFieldValue && filterFieldName){
                rPage = await adminApi.apiAdminRegistrationsDatePost({ date: mainPageContext.selectedDate, paging: {...paging, filterBy: filterFieldName, filterByValue: filterFieldValue} })
            }
            else {
                rPage = await adminApi.apiAdminRegistrationsDatePost({ date: mainPageContext.selectedDate, paging: paging })
            }
        }
        else {
            if(filterFieldValue && filterFieldName){
                rPage = await adminApi.apiAdminRegistrationsPost({ paging: {...paging, filterBy: filterFieldName, filterByValue: filterFieldValue} });
            }
            else {
                rPage = await adminApi.apiAdminRegistrationsPost({ paging: paging });
            }
            
        }

        await setRegistrations(rPage.page!);

        await setRegistrationsCount(rPage.totalCount!);

        setRegistrationsLoading(false);

        return rPage.page!;

    }


    const exportRegistrationsForAthlete = async (row: RegistrationStaffInfo) => {

        let t = await silentRequest();

        let adminApi = new AdminApi(t);

        let result = await adminApi.apiAdminRegistrationsIdPost({ id: parseInt(row.athleteId!) })

        await CreatePDF(row, result.totalCount!);

    };

    const silentRequest = () => {
        if (msalContext.accounts[0]) {
            const accessTokenRequest: SilentRequest = {
                scopes: apiScopes,
                account: msalContext.accounts[0] as AccountInfo,
                authority: apiAuthority,
            } as SilentRequest;

            return msalContext.instance
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    const accessToken = response.accessToken;
                    let c: Configuration = new Configuration({
                        accessToken: accessToken,
                        basePath: backendBasePath,
                    });
                    return c;
                });
        }
        return Promise.reject();
    };

    return {
        registrations,
        registrationsLoading,
        registrationsCount,
        getRegistrations,
        searchRegistrations,
        exportRegistrationsForAthlete,
        filterFieldName, 
        filterFieldValue,
        setFilterFieldName, 
        setFilterFieldValue
    }

}