import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles'
import React, { useState } from 'react';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { AdminContext } from '../../../../context/AdminContext';
import { stateToHTML } from 'draft-js-export-html';
import MUIRichTextEditor from 'mui-rte';
import { EditorState } from 'draft-js'
import { CountryStatistic } from '../../../../dal';
import { ThemeProvider } from '@mui/styles';


export interface AdminCountryActionFieldProps {
    row: CountryStatistic;
}

const myTheme = createTheme({
    // Set up your custom MUI theme here
})


export default function AdminCountryActionField(props: AdminCountryActionFieldProps) {

    const [showNotificationDialog, setShowNotificationDialog] = useState(false);

    const [recipientsText, setRecipientsText] = React.useState("");
    const [message, setMessage] = React.useState("");

    const context = React.useContext(AdminContext);

    const handleCloseDialog = () => {
        setShowNotificationDialog(false);
        resetValues();
    }

    const handleShowDialog = async () => {

        setShowNotificationDialog(true);

        let rec = await context.getNocMember(props.row.countryName!);

        if (rec!.length > 0) {
            let recip: string[] = []
            rec?.forEach(r => { recip.push(r!.email!) });
            setRecipientsText(recip.join(';'))
        }

    }

    const change = (state: EditorState) => {
        let html = stateToHTML(state.getCurrentContent());
        setMessage(html);
    }

    const handleSend = async() => {
        await context.sendNotification(message, props.row!.countryName!, recipientsText);
        setShowNotificationDialog(false);
        resetValues();
    }

    const resetValues = () => {
        setMessage("");
        setRecipientsText("");
    }


    return <div>
        <IconButton onClick={() => handleShowDialog()} aria-label="delete" size="small">
            <CircleNotificationsIcon fontSize="inherit" />
        </IconButton>
        <Dialog open={showNotificationDialog} onClose={() => setShowNotificationDialog(false)}>
                <DialogTitle>Send notification to NOC Team</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Send a message to the NOC representatives of the selected country.
                    </DialogContentText>
                    <Stack spacing={4} sx={{marginTop:'20px'}}>
                        <TextField onChange={(event) => setRecipientsText(event.target.value) } value={recipientsText} id="standard-basic" label="Recipients" variant="standard" />
                        <Box sx={{ marginBottom: "60px",padding:"10px", border:'1px solid black', minHeight:"200px" }}>
                            <ThemeProvider theme={myTheme}>
                                <MUIRichTextEditor 
                                    label="A message that will associated with the email..." 
                                    onChange={change} />
                            </ThemeProvider>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                        <Button type="submit" onClick={() => { handleSend() }}>Send notification</Button>
                        <Button type="submit" onClick={() => handleCloseDialog()}>Close</Button>
                    </DialogActions>
            </Dialog>
    </div>;
}
