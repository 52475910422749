/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoomingUser,
    RoomingUserFromJSON,
    RoomingUserFromJSONTyped,
    RoomingUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    eventId?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    eventName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    eventStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    eventEnd?: Date;
    /**
     * 
     * @type {Array<RoomingUser>}
     * @memberof Event
     */
    users?: Array<RoomingUser> | null;
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'eventStart': !exists(json, 'eventStart') ? undefined : (new Date(json['eventStart'])),
        'eventEnd': !exists(json, 'eventEnd') ? undefined : (new Date(json['eventEnd'])),
        'users': !exists(json, 'users') ? undefined : (json['users'] === null ? null : (json['users'] as Array<any>).map(RoomingUserFromJSON)),
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'eventName': value.eventName,
        'eventStart': value.eventStart === undefined ? undefined : (value.eventStart.toISOString()),
        'eventEnd': value.eventEnd === undefined ? undefined : (value.eventEnd.toISOString()),
        'users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(RoomingUserToJSON)),
    };
}

