import React, { useContext, useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridSelectionModel, GridCallbackDetails, GridRowId, getGridStringOperators, GridColTypeDef, GridFilterItem, GridCellParams } from '@mui/x-data-grid';
import { MainPageContext } from '../../../context/MainPageContext';
import AthleteToolBar from './AthleteToolBar/AthleteToolBar';
import { MuiEvent } from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid';
import { Athlete, Registration } from '../../../dal';
import AthleteRegistrationStatusIndicator from './AthleteRegistrationStatusIndicator/AthleteRegistrationStatusIndicator';
import { useNavigate } from 'react-router-dom';
import StatusFilterSelector from './StatusFilterSelector/StatusFilterSelector';
import FilterStatusIndicator from '../FilterStatusIndicator/FilterStatusIndicator';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';


export default function AthleteDataGrid() {

  let navigate = useNavigate();
  const appInsights = useAppInsightsContext();


  useEffect(() => {
    appInsights.trackPageView({ name: 'Home' })
    return () => {

    }
    // eslint-disable-next-line
  }, [])

  const handleAthleteSelection = (row: GridRowParams) => {
    navigate("/athlete/" + (row.row as Athlete).accreditationNumber);
  }



  const sportColumnType: GridColTypeDef = {
    extendType: 'string',
    filterOperators: getGridStringOperators()
      .filter((operator) => operator.value === 'equals' || operator.value === 'contains')
      .map((operator) => {
        let x = {
          ...operator,
          InputComponentProps: {
          },
        }

        x.getApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef): any => {
          if (filterItem.value === undefined) {
            return true;
          }
          return (params: GridCellParams): boolean => {
            return String(params.row.discipline.sportName).toLowerCase().indexOf(String(filterItem.value).toLowerCase()) > -1;
          };
        }
        return x;
      }),
    sortComparator: (v1, v2, param1, param2) => {
      let first = param1.api.getCellParams(param1.id, "sport").row.discipline.sportName
      let second = param1.api.getCellParams(param2.id, "sport").row.discipline.sportName
      return (first > second) ? 1 : -1;
    }
  };

  const statusColumnType: GridColTypeDef = {
    extendType: 'string',
    renderCell: (params) => { return <div><AthleteRegistrationStatusIndicator registrations={params.row.registrations as Registration[]} /></div> },
    filterOperators: getGridStringOperators()
      .filter((operator) => operator.value === 'equals')
      .map((operator) => {

        let x = { ...operator };

        x.InputComponent = StatusFilterSelector;

        x.getApplyFilterFn = (filterItem: GridFilterItem, column: GridColDef): any => {
          if (filterItem.value === undefined) {
            return true;
          }

          return (params: GridCellParams): boolean => {

            let registrations: Registration[] = params.row.registrations as Registration[];

            if (filterItem.value === 'Missing') {
              if (registrations.length === 0) {
                return true;
              }
            }

            if (filterItem.value === 'Incomplete') {
              if (registrations.length > 0 && registrations.filter(r => r.isComplete === false).length > 0) {
                return true;
              }
            }

            if (filterItem.value === 'Complete') {
              if (registrations.length > 0 && registrations.filter(r => r.isComplete === false).length === 0) {
                return true;
              }
            }
            if (filterItem.value === 'All') {
              return true;
            }

            return false;
          }

        }

        return x;
      })
  }

  const { athletes, athleteListFilter, setAthleteListFilter, filteredAthletes, searchTerm } = useContext(MainPageContext);

  const columns: GridColDef[] = [
    { field: 'accreditationNumber', headerName: 'Accreditation no.', width: 150 },
    { field: 'firstName', headerName: 'First Name', width: 150 },
    { field: 'lastName', headerName: 'Last Name', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 150, renderCell: (params) => { return <div>{params.row.gender === 0 ? "Male" : "Female"}</div> } },
    { field: 'country', headerName: 'Country', width: 150, renderCell: (params) => { return <div>{params.row.country.countryName}</div> } },
    { field: 'sport', headerName: 'Sport', type: "sport", width: 150, renderCell: (params) => { return <div>{params.row.discipline.sportName}</div> } },
    { field: 'status', type: 'status', headerName: 'Status', width: 150 }
  ];

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

  return (
    <div style={{ height: "90vh", padding: "4px 4px 4px 4px" }}>
      {
        (selectedRows.length > 0) &&
        <AthleteToolBar selectedRows={selectedRows}></AthleteToolBar>
      }
      <FilterStatusIndicator />
      <DataGrid
        checkboxSelection
        pagination
        disableSelectionOnClick
        disableDensitySelector
        onRowClick={(params: GridRowParams, event: MuiEvent<React.SyntheticEvent>, details: GridCallbackDetails) => { handleAthleteSelection(params) }}
        autoPageSize
        filterModel={athleteListFilter!}
        onFilterModelChange={(model) => setAthleteListFilter(model)}
        rows={searchTerm ? filteredAthletes : athletes}
        columns={columns}
        columnTypes={{ sport: sportColumnType, status: statusColumnType }}
        getRowId={(r: any) => r.athleteId}
        onSelectionModelChange={(selectionModel: GridSelectionModel, details: GridCallbackDetails) => { setSelectedRows(selectionModel) }}
      />
    </div>
  )
}

export const MemoizedAthleteDataGrid = React.memo(AthleteDataGrid);