import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { msalConfig } from "./config/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.Fragment>
    <React.StrictMode>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  </React.Fragment>,
  document.getElementById('root')
);