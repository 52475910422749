import { Button, CircularProgress, Typography } from '@mui/material'
import React, { useContext } from 'react'
import styles from './UnauthorizedUserPage.module.css'
import logo from '../../resources/images/ita_color_rgb_big.png'
import { MainPageContext } from '../../context/MainPageContext';
import { useAccount, useMsal } from '@azure/msal-react';

export default function UnauthorizedUserPage() {

    const { isLoading } = useContext(MainPageContext);

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    return (
        <div className={styles.loginContainer}>
            <div className={styles.parent}>
                {isLoading &&
                    <CircularProgress />
                }

                {!isLoading &&
                    <div className={styles.box}>
                        <div>
                            <Typography variant="h4">Your login ({account?.username}) was successful but the user has no access to the ITA Rooming Application or there was an error when connecting to the Rooming Application.</Typography>
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <Button fullWidth variant="contained" size="large">
                                Logout
                            </Button>
                        </div>
                    </div>
                }
            </div>
            <div className={styles.bar}>
                <div style={{ display: 'flex', alignItems: "center", height: '100%', padding: '0px 30px 0px 30px', justifyContent: 'space-between' }}>
                    <img src={logo} height="30" alt="ITA Logo"></img>
                    <div>
                        <Typography variant="h5">
                            Cookie policy
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}
