/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RoomingUser,
    RoomingUserFromJSON,
    RoomingUserToJSON,
} from '../models';

export interface ApiRoomingUserIdDeleteRequest {
    id: number;
}

export interface ApiRoomingUserIdGetRequest {
    id: number;
}

export interface ApiRoomingUserIdPutRequest {
    id: number;
    roomingUser?: RoomingUser;
}

export interface ApiRoomingUserPostRequest {
    roomingUser?: RoomingUser;
}

/**
 * 
 */
export class RoomingUserApi extends runtime.BaseAPI {

    /**
     */
    async apiRoomingUserCurrentGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoomingUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RoomingUser/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoomingUserFromJSON(jsonValue));
    }

    /**
     */
    async apiRoomingUserCurrentGet(initOverrides?: RequestInit): Promise<RoomingUser> {
        const response = await this.apiRoomingUserCurrentGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRoomingUserGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RoomingUser>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RoomingUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoomingUserFromJSON));
    }

    /**
     */
    async apiRoomingUserGet(initOverrides?: RequestInit): Promise<Array<RoomingUser>> {
        const response = await this.apiRoomingUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRoomingUserIdDeleteRaw(requestParameters: ApiRoomingUserIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRoomingUserIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RoomingUser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRoomingUserIdDelete(requestParameters: ApiRoomingUserIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRoomingUserIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRoomingUserIdGetRaw(requestParameters: ApiRoomingUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoomingUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRoomingUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RoomingUser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoomingUserFromJSON(jsonValue));
    }

    /**
     */
    async apiRoomingUserIdGet(requestParameters: ApiRoomingUserIdGetRequest, initOverrides?: RequestInit): Promise<RoomingUser> {
        const response = await this.apiRoomingUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRoomingUserIdPutRaw(requestParameters: ApiRoomingUserIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRoomingUserIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RoomingUser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoomingUserToJSON(requestParameters.roomingUser),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRoomingUserIdPut(requestParameters: ApiRoomingUserIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRoomingUserIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRoomingUserPostRaw(requestParameters: ApiRoomingUserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/RoomingUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoomingUserToJSON(requestParameters.roomingUser),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRoomingUserPost(requestParameters: ApiRoomingUserPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRoomingUserPostRaw(requestParameters, initOverrides);
    }

}
