import { Box, IconButton, Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, Typography, Button, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AdminContext } from '../../../context/AdminContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AthleteScheduleItem from './AthleteScheduleItem/AthleteScheduleItem';
import TeamScheduleItem from './TeamScheduleItem/TeamScheduleItem';
import { MainPageContext } from '../../../context/MainPageContext';
import { GetUserRole } from '../../../utils/UserMapper';
import { CountryDisciplineTeamEvent } from '../../../dal';
import CDScheduleItem from './CDScheduleItem/CDScheduleItem';


export default function AthleteSchedule() {

    const params = useParams();
    const navigate = useNavigate();
    const context = useContext(AdminContext);
    const mainPageContext = useContext(MainPageContext);

    const [showExtendedTeamEventsDialog, setShowExtendedTeamEventsDialog] = useState(false);
    const [extendedEvents, setExtendedEvents] = useState<CountryDisciplineTeamEvent[]>([]);

    const handleShowExtendedTeamEvents = () => {
        setShowExtendedTeamEventsDialog(true);

        context.getCDTeamEvents(params.accreditationNumber!)?.then( r => {
            setExtendedEvents(r);
        })

    }

    const handleCloseExtendedTeamEvents = () => { 
        setShowExtendedTeamEventsDialog(false);
        setExtendedEvents([]);
    }

    useEffect(() => {
        if (params.accreditationNumber)
            context.refreshSelectedAthlete(params.accreditationNumber!);
        // eslint-disable-next-line
    }, [])

    const handleBack = () => {
        if (GetUserRole(mainPageContext.currentUser!) === 'RoomingAdmin') {
            navigate("/");
        }
        if (GetUserRole(mainPageContext.currentUser!) === 'RoomingStaff') {
            navigate("/");
        }
    }


    return (
        <Box padding={"10px"}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={handleBack}
                >
                    <ArrowBackIcon />
                </IconButton>
                <div style={{ marginLeft: "50px" }}>
                    <Typography variant="h4">
                        {context.selectedAthlete?.fullName}
                    </Typography>
                </div>
            </div>
            <div style={{ padding: "20px 20px 20px 20px" }}>
                <TextField
                    label="Accreditation number:"
                    value={context.selectedAthlete?.accreditationNumber || ""}
                    variant="outlined"
                    fullWidth
                    disabled
                />

                <br />
                <br />

                <TextField
                    label="Country:"
                    value={context.selectedAthlete?.country?.countryName || ""}
                    variant="outlined"
                    fullWidth
                    disabled
                />

                <br />
                <br />

                <Stack direction={'row'} spacing={3}>
                <TextField
                    label="Sport:"
                    value={context.selectedAthlete?.discipline?.sportName || ""}
                    variant="outlined"
                    fullWidth
                    disabled
                />
                <TextField
                    label="ODF Sport:"
                    value={context.selectedAthlete?.discipline?.sportName || ""}
                    variant="outlined"
                    fullWidth
                    disabled
                />
                </Stack>

                <div>
                    <Typography variant='h4' sx={{ marginTop: 3 }}>Individual Events</Typography>

                    <TableContainer sx={{ marginTop: 5 }} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Event name</TableCell>
                                    <TableCell align="center">Location</TableCell>
                                    <TableCell align="center">Venue</TableCell>
                                    <TableCell align="center">Discipline</TableCell>
                                    <TableCell align="center">Start date</TableCell>
                                    <TableCell align="center">End date</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {context.individualEvents.map((event, index) => {
                                    return (<AthleteScheduleItem event={event} />)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Stack direction={'row'} alignContent={'center'} alignItems={'center'} justifyContent={'space-between'} sx={{marginTop:'30px'}}>
                        <div>
                            <Typography variant='h4'>Team Events</Typography>
                        </div>
                        <div>
                            <Button onClick={() => handleShowExtendedTeamEvents()} variant="outlined" size="small">View extended events</Button>
                        </div>
                    </Stack>

                    <TableContainer sx={{ marginTop: 5 }} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Event name</TableCell>
                                    <TableCell align="center">Team name</TableCell>
                                    <TableCell align="center">Discipline</TableCell>
                                    <TableCell align="center">Location</TableCell>
                                    <TableCell align="center">Venue</TableCell>
                                    <TableCell align="center">Start date</TableCell>
                                    <TableCell align="center">End date</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {context.teamEvents.map((event, index) => {
                                    return (<TeamScheduleItem teamEvent={event} />)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

            <Dialog open={showExtendedTeamEventsDialog} onClose={() => handleCloseExtendedTeamEvents()} maxWidth={'lg'} fullWidth={true}>
                <DialogTitle>Extended Team Events</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                                A list of team events based on the athletes country and discipline.
                    </DialogContentText>
                    <TableContainer sx={{ marginTop: 5 }} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Event name</TableCell>
                                    <TableCell align="center">Location</TableCell>
                                    <TableCell align="center">Venue</TableCell>
                                    <TableCell align="center">Discipline</TableCell>
                                    <TableCell align="center">Start date</TableCell>
                                    <TableCell align="center">End date</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {extendedEvents.map((event, index) => {
                                    return(<CDScheduleItem event={event} />)
                                })}
                            </TableBody>
                            </Table>
                            </TableContainer>
                </DialogContent>
                <DialogActions>
                        <Button type="submit" onClick={() => handleCloseExtendedTeamEvents()}>Close</Button>
                    </DialogActions>
            </Dialog>
        </Box>
    )
}
