/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryDisciplineTeamEvent
 */
export interface CountryDisciplineTeamEvent {
    /**
     * 
     * @type {string}
     * @memberof CountryDisciplineTeamEvent
     */
    participantCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDisciplineTeamEvent
     */
    eventCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDisciplineTeamEvent
     */
    discipline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDisciplineTeamEvent
     */
    unitCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDisciplineTeamEvent
     */
    unitName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDisciplineTeamEvent
     */
    unitLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryDisciplineTeamEvent
     */
    venue?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CountryDisciplineTeamEvent
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CountryDisciplineTeamEvent
     */
    endDate?: Date;
}

export function CountryDisciplineTeamEventFromJSON(json: any): CountryDisciplineTeamEvent {
    return CountryDisciplineTeamEventFromJSONTyped(json, false);
}

export function CountryDisciplineTeamEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryDisciplineTeamEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'participantCode': !exists(json, 'participantCode') ? undefined : json['participantCode'],
        'eventCode': !exists(json, 'eventCode') ? undefined : json['eventCode'],
        'discipline': !exists(json, 'discipline') ? undefined : json['discipline'],
        'unitCode': !exists(json, 'unitCode') ? undefined : json['unitCode'],
        'unitName': !exists(json, 'unitName') ? undefined : json['unitName'],
        'unitLocation': !exists(json, 'unitLocation') ? undefined : json['unitLocation'],
        'venue': !exists(json, 'venue') ? undefined : json['venue'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
    };
}

export function CountryDisciplineTeamEventToJSON(value?: CountryDisciplineTeamEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participantCode': value.participantCode,
        'eventCode': value.eventCode,
        'discipline': value.discipline,
        'unitCode': value.unitCode,
        'unitName': value.unitName,
        'unitLocation': value.unitLocation,
        'venue': value.venue,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
    };
}

