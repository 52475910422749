/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegistrationStaffInfo,
    RegistrationStaffInfoFromJSON,
    RegistrationStaffInfoFromJSONTyped,
    RegistrationStaffInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegistrationStaffResult
 */
export interface RegistrationStaffResult {
    /**
     * 
     * @type {Array<RegistrationStaffInfo>}
     * @memberof RegistrationStaffResult
     */
    page?: Array<RegistrationStaffInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof RegistrationStaffResult
     */
    totalCount?: number;
}

export function RegistrationStaffResultFromJSON(json: any): RegistrationStaffResult {
    return RegistrationStaffResultFromJSONTyped(json, false);
}

export function RegistrationStaffResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationStaffResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : (json['page'] === null ? null : (json['page'] as Array<any>).map(RegistrationStaffInfoFromJSON)),
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
    };
}

export function RegistrationStaffResultToJSON(value?: RegistrationStaffResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page === undefined ? undefined : (value.page === null ? null : (value.page as Array<any>).map(RegistrationStaffInfoToJSON)),
        'totalCount': value.totalCount,
    };
}

