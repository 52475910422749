/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './';

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    roleName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Role
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Role
     */
    modified?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    deleted?: boolean;
    /**
     * 
     * @type {Event}
     * @memberof Role
     */
    event?: Event;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    modifiedBy?: string | null;
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'event': !exists(json, 'event') ? undefined : EventFromJSON(json['event']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
    };
}

export function RoleToJSON(value?: Role | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'roleName': value.roleName,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'deleted': value.deleted,
        'event': EventToJSON(value.event),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
    };
}

