/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamEvent
 */
export interface TeamEvent {
    /**
     * 
     * @type {string}
     * @memberof TeamEvent
     */
    accreditationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamEvent
     */
    discipline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TeamEvent
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TeamEvent
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof TeamEvent
     */
    venue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamEvent
     */
    unitLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamEvent
     */
    unitName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamEvent
     */
    teamName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TeamEvent
     */
    teamSize?: number;
}

export function TeamEventFromJSON(json: any): TeamEvent {
    return TeamEventFromJSONTyped(json, false);
}

export function TeamEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accreditationNumber': !exists(json, 'accreditationNumber') ? undefined : json['accreditationNumber'],
        'discipline': !exists(json, 'discipline') ? undefined : json['discipline'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'venue': !exists(json, 'venue') ? undefined : json['venue'],
        'unitLocation': !exists(json, 'unitLocation') ? undefined : json['unitLocation'],
        'unitName': !exists(json, 'unitName') ? undefined : json['unitName'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'teamSize': !exists(json, 'teamSize') ? undefined : json['teamSize'],
    };
}

export function TeamEventToJSON(value?: TeamEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accreditationNumber': value.accreditationNumber,
        'discipline': value.discipline,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'venue': value.venue,
        'unitLocation': value.unitLocation,
        'unitName': value.unitName,
        'teamName': value.teamName,
        'teamSize': value.teamSize,
    };
}

