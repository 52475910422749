import { RegistrationType } from "../dal"

export const GetRegistrationType = (regType: string): RegistrationType => {
    switch (regType) {
        case "Zhangjiakou Olympic Village":
            return RegistrationType.NUMBER_0
        case "Beijing Olympic Village":
            return RegistrationType.NUMBER_1
        case "Yanqing Olympic Village":
            return RegistrationType.NUMBER_2
        case "hotel":
            return RegistrationType.NUMBER_3
        case "Hotel":
            return RegistrationType.NUMBER_3
        case "Private":
            return RegistrationType.NUMBER_4
        default:
            return RegistrationType.NUMBER_3
    }
}

export const GetRegistrationLabel = (regType: RegistrationType): string => {
    switch (regType) {
        case RegistrationType.NUMBER_0:
            return "Zhangjiakou Olympic Village"
        case RegistrationType.NUMBER_1:
            return "Beijing Olympic Village"
        case RegistrationType.NUMBER_2:
            return "Yanqing Olympic Village"
        case RegistrationType.NUMBER_3:
                return "Hotel"
        case RegistrationType.NUMBER_4:
                return "Private"
        default:
            return "Hotel"
    }
}

export const GetRegistrationLabelFromShort  = ( s: string) : string => {  

    switch(s) {
        case 'ZhangjiakouOlympicVillage': 
            return "Zhangjiakou Olympic Village"
        case 'BeijingOlympicVillage': 
            return "Beijing Olympic Village"
        case 'YanqingOlympicVillage':
            return 'Yanqing Olympic Village'
        case 'Hotel':
            return 'Hotel'
        case 'Private':
            return 'Private'
        default: 
            return ''
    }

}