import React from 'react'
import AdminAthleteList from './AdminAthleteList/AdminAthleteList';

export default function AdminPage() {

    return (
        <div>
            <AdminAthleteList></AdminAthleteList>
        </div>
    )
}
