/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './';

/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Country
     */
    deleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Country
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Country
     */
    modified?: Date;
    /**
     * 
     * @type {Event}
     * @memberof Country
     */
    event?: Event;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    modifiedBy?: string | null;
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'event': !exists(json, 'event') ? undefined : EventFromJSON(json['event']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
    };
}

export function CountryToJSON(value?: Country | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryId': value.countryId,
        'countryName': value.countryName,
        'countryCode': value.countryCode,
        'deleted': value.deleted,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'event': EventToJSON(value.event),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
    };
}

