import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { appInsightsBackend } from '../config/config';

const browserHistory = createBrowserHistory({});
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: appInsightsBackend,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        },
        distributedTracingMode: DistributedTracingModes.W3C,
        disableAjaxTracking: false,
        disableFetchTracking: false,
        enableCorsCorrelation: true
    }
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };