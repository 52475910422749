/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discipline,
    DisciplineFromJSON,
    DisciplineFromJSONTyped,
    DisciplineToJSON,
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './';

/**
 * 
 * @export
 * @interface Federation
 */
export interface Federation {
    /**
     * 
     * @type {number}
     * @memberof Federation
     */
    federationId?: number;
    /**
     * 
     * @type {string}
     * @memberof Federation
     */
    federationName?: string | null;
    /**
     * 
     * @type {Array<Discipline>}
     * @memberof Federation
     */
    disciplines?: Array<Discipline> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Federation
     */
    deleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Federation
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Federation
     */
    modified?: Date;
    /**
     * 
     * @type {Event}
     * @memberof Federation
     */
    event?: Event;
    /**
     * 
     * @type {string}
     * @memberof Federation
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Federation
     */
    modifiedBy?: string | null;
}

export function FederationFromJSON(json: any): Federation {
    return FederationFromJSONTyped(json, false);
}

export function FederationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Federation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'federationId': !exists(json, 'federationId') ? undefined : json['federationId'],
        'federationName': !exists(json, 'federationName') ? undefined : json['federationName'],
        'disciplines': !exists(json, 'disciplines') ? undefined : (json['disciplines'] === null ? null : (json['disciplines'] as Array<any>).map(DisciplineFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'event': !exists(json, 'event') ? undefined : EventFromJSON(json['event']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
    };
}

export function FederationToJSON(value?: Federation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'federationId': value.federationId,
        'federationName': value.federationName,
        'disciplines': value.disciplines === undefined ? undefined : (value.disciplines === null ? null : (value.disciplines as Array<any>).map(DisciplineToJSON)),
        'deleted': value.deleted,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'event': EventToJSON(value.event),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
    };
}

