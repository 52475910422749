import { Button, Paper } from '@mui/material'
import React, { useContext } from 'react'
import { MainPageContext } from '../../../context/MainPageContext';

export default function FilterStatusIndicator() {

    const { athleteListFilter, setAthleteListFilter } = useContext(MainPageContext);

    const removeFilter = () => {

        let newFilter = {
            items: [],
        };

        setAthleteListFilter(newFilter);
    }

    return (
        <React.Fragment>
            {athleteListFilter!.items.length > 0 &&
                <Paper sx={{ padding: "15px 20px 15px 20px", marginTop: "10px", marginBottom: "10px", backgroundColor: "#00A9E6", color: "#e6e6e6" }} elevation={3} >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent:"space-between" , alignItems:"center"}}>
                        <div>
                            Only showing athletes with <b>{athleteListFilter != null && athleteListFilter.items[0].value ? (athleteListFilter!.items[0].value!).toLowerCase() : ""}</b> registrations.
                        </div>
                        <Button onClick={removeFilter} variant="contained" >Remove filter</Button>
                    </div>
                </Paper>
            }
        </React.Fragment>
    )
}