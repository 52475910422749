import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import { useContext } from 'react';
import { MainPageContext } from '../../../context/MainPageContext';
import EventIcon from '@mui/icons-material/Event';
import { Button, Popover, Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import MomentAdapter from '@mui/lab/AdapterMoment';
import moment, { Moment } from 'moment';
import { GetUserRole } from '../../../utils/UserMapper';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

interface AppTopBarProps {
  onDrawerOpen: () => void;
}

export default function AppTopBar(props: AppTopBarProps) {

  moment.tz.setDefault('GMT');

  const { setShowWelcomePage, setSearchTerm, selectedDate, setSelectedDate, currentUser } = useContext(MainPageContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [value, setValue] = React.useState<Date | Moment | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetDateFilter = () => {
    
    let x = moment(value);

    setSelectedDate(x.hour(0).minute(0).second(0).tz('GMT').toDate());

    setAnchorEl(null);
    
  }

  const handleClearDateFilter = () => {
    setSelectedDate(null);
    setValue(null);
  }

  function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
  ): (...args: Params) => void {
    let timer: NodeJS.Timeout
    return (...args: Params) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, timeout)
    }
  }

  function updateSearchString(message: string) {
    setSearchTerm(message)
  }

  const debouncedUpdateSearchString = debounce(updateSearchString, 800);


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={props.onDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            ITA ROOMING
          </Typography>
          {GetUserRole(currentUser!) &&
            <Stack direction={'row'} spacing={3} alignContent={'center'} alignItems={'center'}>
              {selectedDate &&
                <Typography>
                  {
                    moment(selectedDate).format("MM/DD")
                  }
                </Typography>
              }
              <IconButton style={{ marginRight: "20px" }} aria-label="show info" onClick={handleClick}>
                <EventIcon color={'info'} />
              </IconButton>
            </Stack>
          }
          <IconButton style={{ marginRight: "20px" }} aria-label="show info" onClick={() => { setShowWelcomePage(true) }}>
            <InfoIcon color={'info'} />
          </IconButton>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onChange={(value) => { debouncedUpdateSearchString(value.target.value) }}
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Toolbar>
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LocalizationProvider dateAdapter={MomentAdapter} locale={'en-us'}>
          <Stack spacing={3} sx={{ padding: '14px' }}>
            <DatePicker
              openTo="year"
              views={['month', 'day']}
              label="Month and date"
              value={value}
              onChange={(newValue: any) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
            <Button variant={'contained'} onClick={() => handleSetDateFilter()}>Set date filter</Button>
            <Button onClick={() => handleClearDateFilter()}>Clear date filter</Button>

          </Stack>
        </LocalizationProvider>
      </Popover>
    </Box>
  );
}