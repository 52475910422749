/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
    RegistrationType,
    RegistrationTypeFromJSON,
    RegistrationTypeFromJSONTyped,
    RegistrationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Registration
 */
export interface Registration {
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    registrationId?: number;
    /**
     * 
     * @type {RegistrationType}
     * @memberof Registration
     */
    registrationType?: RegistrationType;
    /**
     * 
     * @type {number}
     * @memberof Registration
     */
    athleteId?: number;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    streetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    administrativeArea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    floor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    additionalComments?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Registration
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Registration
     */
    toDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Registration
     */
    isComplete?: boolean;
    /**
     * 
     * @type {Event}
     * @memberof Registration
     */
    event?: Event;
    /**
     * 
     * @type {boolean}
     * @memberof Registration
     */
    deleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Registration
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Registration
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Registration
     */
    modifiedBy?: string | null;
}

export function RegistrationFromJSON(json: any): Registration {
    return RegistrationFromJSONTyped(json, false);
}

export function RegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Registration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationId': !exists(json, 'registrationId') ? undefined : json['registrationId'],
        'registrationType': !exists(json, 'registrationType') ? undefined : RegistrationTypeFromJSON(json['registrationType']),
        'athleteId': !exists(json, 'athleteId') ? undefined : json['athleteId'],
        'streetAddress': !exists(json, 'streetAddress') ? undefined : json['streetAddress'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'administrativeArea': !exists(json, 'administrativeArea') ? undefined : json['administrativeArea'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'additionalComments': !exists(json, 'additionalComments') ? undefined : json['additionalComments'],
        'fromDate': !exists(json, 'fromDate') ? undefined : (new Date(json['fromDate'])),
        'toDate': !exists(json, 'toDate') ? undefined : (new Date(json['toDate'])),
        'isComplete': !exists(json, 'isComplete') ? undefined : json['isComplete'],
        'event': !exists(json, 'event') ? undefined : EventFromJSON(json['event']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
    };
}

export function RegistrationToJSON(value?: Registration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationId': value.registrationId,
        'registrationType': RegistrationTypeToJSON(value.registrationType),
        'athleteId': value.athleteId,
        'streetAddress': value.streetAddress,
        'buildingName': value.buildingName,
        'city': value.city,
        'administrativeArea': value.administrativeArea,
        'postalCode': value.postalCode,
        'roomNumber': value.roomNumber,
        'floor': value.floor,
        'additionalComments': value.additionalComments,
        'fromDate': value.fromDate === undefined ? undefined : (value.fromDate.toISOString()),
        'toDate': value.toDate === undefined ? undefined : (value.toDate.toISOString()),
        'isComplete': value.isComplete,
        'event': EventToJSON(value.event),
        'deleted': value.deleted,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
    };
}

