import { SwipeableDrawer } from '@mui/material'
import React, { SyntheticEvent } from 'react'


import styles from './AppSideMenu.module.css'
import AppSideMenuOptions from './AppSideMenuOptions/AppSideMenuOptions'
import AppSideMenuTitle from './AppSideMenuTitle/AppSideMenuTitle'
import AppSideMenuUserOptions from './AppSideMenuUserOptions/AppSideMenuUserOptions'

interface AppSideMenuProps {
    isOpen: boolean
    onClose: (event: SyntheticEvent<{}, Event>) => void;
    onOpen: (event: SyntheticEvent<{}, Event>) => void;
}

export default function AppSideMenu(props: AppSideMenuProps) {

    return (
        <div>
            <SwipeableDrawer classes={{}} onClose={props.onClose} onOpen={props.onOpen} open={props.isOpen}>
                <div className={styles.menuContainer}>
                    <div>
                        <AppSideMenuTitle></AppSideMenuTitle>
                    </div>
                    <div>
                        <AppSideMenuOptions></AppSideMenuOptions>
                    </div>
                    <div>
                        <AppSideMenuUserOptions></AppSideMenuUserOptions>
                    </div>
                </div>
            </SwipeableDrawer>
        </div>
    )
}
