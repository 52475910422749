import React from 'react'
import { StaffContext, useStaffContext } from '../../context/StaffContext'
import RegistrationsList from './RegistrationsList/RegistrationsList';

export default function StaffPage() {

    const staffContext = useStaffContext();

    return (
        <StaffContext.Provider value={staffContext}>
        <div>
            <RegistrationsList></RegistrationsList>
        </div>
        </StaffContext.Provider>
    )
}
