import { TableRow, TableCell, Tooltip, IconButton } from '@mui/material';
import moment from 'moment';
import React, { useRef } from 'react';
import { CountryDisciplineTeamEvent } from '../../../../dal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


interface CDScheduleItemProps {
    event: CountryDisciplineTeamEvent;
}

export default function CDScheduleItem(props: CDScheduleItemProps) {


    const textAreaRef = useRef<any>(null);

    // eslint-disable-next-line
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    function copyToClipboard(event: React.MouseEvent<HTMLButtonElement>) {
        function listener(e: any) {
            let str = textAreaRef.current.innerHTML;
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);

        setAnchorEl(event.currentTarget);

        const delayDebounceFn = setTimeout(() => {
            setAnchorEl(null);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }

    return (
        <React.Fragment>
            <TableRow
                key={props.event.unitName}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="center" component="th" scope="row">
                    {props.event.unitName}
                </TableCell>
                <TableCell align="center">{props.event.unitLocation}</TableCell>
                <TableCell align="center">{props.event.venue}</TableCell>
                <TableCell align="center">{props.event.discipline}</TableCell>
                <TableCell align="center">{moment(props.event.startDate).format("DD/MM/YYYY hh:mm")}</TableCell>
                <TableCell align="center">{moment(props.event.endDate).format("DD/MM/YYYY hh:mm")}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Copy event data to clipboard">
                        <IconButton onClick={copyToClipboard} color="primary" aria-label="upload picture" component="span" size='small'>
                            <ContentCopyIcon fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <div hidden ref={textAreaRef}>
                <p>Event name: {props.event.unitName}</p>
                <p>Discipline: {props.event.discipline}</p>
                <p>Location: {props.event.unitLocation}</p>
                <p>Venue: {props.event.venue}</p>
                <p>From: {moment(props.event.startDate).format('DD/MM/YYYY hh:mm')}</p>
                <p>To: {moment(props.event.endDate).format('DD/MM/YYYY hh:mm')}</p>
            </div>
        </React.Fragment>
    )
}

