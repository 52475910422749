import React from 'react'
import logo from '../../../../resources/images/ita_color_rgb_big.png'

export default function AppSideMenuTitle() {
    return (
        <div style={{display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"center", padding:"30px 30px 30px 30px"}}>
            <img src={logo} height="100" alt="ITA logo"></img>            
        </div>
    )
}
