import { TableRow, TableCell, Tooltip, IconButton } from '@mui/material'
import moment from 'moment'
import React, { useRef } from 'react'
import { TeamEvent } from '../../../../dal'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


interface TeamScheduleItemProps {
    teamEvent: TeamEvent
}

export default function TeamScheduleItem(props: TeamScheduleItemProps) {

    const textAreaRef = useRef<any>(null);

    // eslint-disable-next-line
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const offset = (new Date()).getTimezoneOffset();

    function copyToClipboard(event: React.MouseEvent<HTMLButtonElement>) {
        function listener(e: any) {
            let str = textAreaRef.current.innerHTML;
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);

        setAnchorEl(event.currentTarget);

        const delayDebounceFn = setTimeout(() => {
            setAnchorEl(null);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }

    return (
        <React.Fragment>
            <TableRow
                key={props.teamEvent.unitName}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="center" component="th" scope="row">
                    {props.teamEvent.unitName}
                </TableCell>
                <TableCell align="center">{props.teamEvent.teamName}</TableCell>
                <TableCell align="center">{props.teamEvent.discipline}</TableCell>
                <TableCell align="center">{props.teamEvent.unitLocation}</TableCell>
                <TableCell align="center">{props.teamEvent.venue}</TableCell>
                <TableCell align="center">{moment(props.teamEvent.startDate).add(-offset, 'minutes').format("DD/MM/YYYY HH:mm")}</TableCell>
                <TableCell align="center">{moment(props.teamEvent.endDate).add(-offset, 'minutes').format("DD/MM/YYYY HH:mm")}</TableCell>
                <TableCell align="center">
                    <Tooltip title="Copy event data to clipboard">
                        <IconButton onClick={copyToClipboard} color="primary" aria-label="upload picture" component="span" size='small'>
                            <ContentCopyIcon fontSize='inherit' />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <div hidden ref={textAreaRef}>
                <p>Event name: {props.teamEvent.unitName}</p>
                <p>Team: {props.teamEvent.teamName}</p>
                <p>Discipline: {props.teamEvent.discipline}</p>
                <p>Location: {props.teamEvent.unitLocation}</p>
                <p>Venue: {props.teamEvent.venue}</p>
                <p>From: {moment(props.teamEvent.startDate).add(-offset, 'minutes').format('DD/MM/YYYY HH:mm')}</p>
                <p>To: {moment(props.teamEvent.endDate).add(-offset, 'minutes').format('DD/MM/YYYY HH:mm')}</p>
            </div>
        </React.Fragment>
    )
}
