/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndividualEvent
 */
export interface IndividualEvent {
    /**
     * 
     * @type {string}
     * @memberof IndividualEvent
     */
    accreditationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndividualEvent
     */
    discipline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IndividualEvent
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IndividualEvent
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof IndividualEvent
     */
    venue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndividualEvent
     */
    unitLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndividualEvent
     */
    unitName?: string | null;
}

export function IndividualEventFromJSON(json: any): IndividualEvent {
    return IndividualEventFromJSONTyped(json, false);
}

export function IndividualEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accreditationNumber': !exists(json, 'accreditationNumber') ? undefined : json['accreditationNumber'],
        'discipline': !exists(json, 'discipline') ? undefined : json['discipline'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'venue': !exists(json, 'venue') ? undefined : json['venue'],
        'unitLocation': !exists(json, 'unitLocation') ? undefined : json['unitLocation'],
        'unitName': !exists(json, 'unitName') ? undefined : json['unitName'],
    };
}

export function IndividualEventToJSON(value?: IndividualEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accreditationNumber': value.accreditationNumber,
        'discipline': value.discipline,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'venue': value.venue,
        'unitLocation': value.unitLocation,
        'unitName': value.unitName,
    };
}

