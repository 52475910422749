import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { MsalContext } from '@azure/msal-react';
import React, { useContext, useEffect, useState } from 'react'
import { Building, BuildingApi, CountryApi, Country, RoomingUser, RoomingUserApi, Athlete, AthleteApi, Registration, RegistrationApi, AdminApi } from './../dal/index'

import { apiScopes, apiAuthority, backendBasePath } from "../config/config";
import { Configuration } from '../dal/index';
import { UploadApi } from '../dal/apis/UploadApi';
import { GridFilterModel } from '@mui/x-data-grid';
import moment from 'moment';
import { GetUserRole } from '../utils/UserMapper';


type MainPageContextType = {
    showWelcomePage: boolean,
    setShowWelcomePage: (b: boolean) => void,
    isLoading: boolean,
    buildings: Building[]
    addBuilding: (b: Building) => void;
    countries: Country[]
    addCountry: (c: Country) => void;
    addUser: (u: RoomingUser) => void;
    currentUser: RoomingUser | null;
    athletes: Athlete[]
    addRegistration: (r: Registration) => void;
    uploadAthleteRegistration: (f: File) => boolean;
    downloadAthleteCsv: () => void;
    hasUploadFinished: boolean;
    setHasUploadFinished: (b: boolean) => void;
    refreshAll: () => void;
    uploadErrors: Blob | null;
    uploadSuccess: Blob | null;
    uploadErrorsCount: number;
    uploadSuccessCount: number;
    athleteListFilter: GridFilterModel | null;
    setAthleteListFilter: (g: GridFilterModel) => void;
    updateRegistration: (r: Registration) => void;
    searchTerm: string;
    setSearchTerm: (s: string) => void;
    filteredAthletes: Athlete[];
    refreshCache: () => void;
    downloadRegistrationsCsv: () => void;
    selectedDate: Date | null;
    setSelectedDate: (d: Date | null) => void,
    isDirty: number
}


export const MainPageContext = React.createContext<MainPageContextType>({
    showWelcomePage: true,
    setShowWelcomePage: (b: boolean) => null,
    isLoading: false,
    buildings: [],
    addBuilding: () => null,
    countries: [],
    addCountry: () => null,
    addUser: () => null,
    currentUser: null,
    athletes: [],
    addRegistration: (r: Registration) => null,
    uploadAthleteRegistration: (f: File) => false,
    downloadAthleteCsv: () => null,
    hasUploadFinished: false,
    setHasUploadFinished: (b: boolean) => null,
    refreshAll: () => null,
    uploadErrors: null,
    uploadSuccess: null,
    uploadErrorsCount: 0,
    uploadSuccessCount: 0,
    athleteListFilter: {
        items: [{ columnField: 'status', value: 'All', operatorValue: 'equals' }],
    },
    setAthleteListFilter: (g: GridFilterModel) => null,
    updateRegistration: (r: Registration) => null,
    searchTerm: "",
    setSearchTerm: (s: string) => null,
    filteredAthletes: [],
    refreshCache: () => null,
    downloadRegistrationsCsv: () => null,
    selectedDate: null,
    setSelectedDate: (d: Date | null) => null,
    isDirty: 0
})

export function useMainPageContext() {

    const msalContext = useContext(MsalContext);

    const [showWelcomePage, setShowWelcomePage] = useState(true);

    const [buildings, setBuildings] = useState<Building[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [currentUser, setCurrentUser] = useState<RoomingUser | null>(null);

    const [uploadErrors, setUploadErrors] = useState<Blob | null>(null);
    const [uploadSuccess, setUploadSuccess] = useState<Blob | null>(null);
    const [uploadErrorsCount, setUploadErrorsCount] = useState(0);
    const [uploadSuccessCount, setUploadSuccessCount] = useState(0);


    const [isDirty, setIsDirty] = useState(0);

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredAthletes, setFilteredAthletes] = useState<Athlete[]>([])

    const [athletes, setAthletes] = useState<Athlete[]>([])

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const [hasUploadFinished, setHasUploadFinished] = useState(false);

    const [athleteListFilter, setAthleteListFilter] = useState<GridFilterModel | null>({
        items: [],
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await refreshAll();
            setIsLoading(false);
        })()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        console.log("filtering");

        console.log(athletes.filter(a => a.firstName?.toLowerCase().indexOf(searchTerm.toLowerCase())! > -1));

        let searchTerms = searchTerm.split(' ');

        let temp: Athlete[] = [];

        //additive filtering

        /*         searchTerms.map(term => {
        
                    let tempAthletes = athletes.filter(a => a.firstName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                        || a.lastName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                        || a.accreditationNumber?.toLowerCase().indexOf(term.toLowerCase())! > -1
                        || a.discipline?.sportName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                    );
                    temp = [...temp, ...tempAthletes];
        
                }); */

        //subtration filtering 

        searchTerms.map(term => {

            if (temp.length === 0) {
                let tempAthletes = athletes.filter(a => a.firstName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                    || a.lastName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                    || a.accreditationNumber?.toLowerCase().indexOf(term.toLowerCase())! > -1
                    || a.discipline?.sportName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                );

                console.log("initial")
                console.log(tempAthletes);

                temp = [...tempAthletes];
            }
            else {
                let tempAthletes = temp.filter(a => a.firstName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                    || a.lastName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                    || a.accreditationNumber?.toLowerCase().indexOf(term.toLowerCase())! > -1
                    || a.discipline?.sportName?.toLowerCase().indexOf(term.toLowerCase())! > -1
                );

                console.log('secondary')

                console.log(tempAthletes);

                temp = [...tempAthletes];
            }
            return 0;
        });

        const ids = temp.map(o => o.accreditationNumber);

        const filtered = temp.filter(({ accreditationNumber }, index) => !ids.includes(accreditationNumber, index + 1))

        setFilteredAthletes(filtered);

        return () => {
            console.log("Cleanup");
        }
        // eslint-disable-next-line
    }, [searchTerm])


    const refreshAll = async () => {
        await refreshCurrentUser();
        await refreshCountries();
        await refreshBuildings();
        await refreshAthletes();
    }

    const refreshCache = () => {
        return silentRequest().then(c => {
            let adminApi = new AdminApi(c);

            adminApi.apiAdminResetCacheGet().then(r => {
                console.log("Cache reset");
            }).catch(e => { console.log(e) })
        })
    }

    const downloadAthleteCsv = () => {

        return silentRequest().then((c) => {
            let athleteapi = new AthleteApi(c);
            athleteapi.apiAthleteDownloadGet().then(r => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([r as Blob]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", currentUser?.country?.countryName + '_' + moment().format('YYYYMMDDhhmm') + '_Export.csv');

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode!.removeChild(link);

            });

        });
    }

    const downloadRegistrationsCsv = () => {
        return silentRequest().then((c) => {

            if (!GetUserRole(currentUser!)) {
                let registrationApi = new RegistrationApi(c);
                registrationApi.apiRegistrationDownloadGet().then(r => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([r as Blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", currentUser?.country?.countryName + '_' + moment().format('YYYYMMDDhhmm') + '_Export.csv');

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode!.removeChild(link);
                });
            }
            else {
                let adminApi = new AdminApi(c);
                adminApi.apiAdminRegistrationsDownloadGet().then(r => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([r as Blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", 'registration_export_' + moment().format('YYYYMMDDhhmm') + '_Export.csv');

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode!.removeChild(link);
                });
            }


        });
    }

    const uploadAthleteRegistration = (f: File): boolean => {

        silentRequest().then(t => {
            let uploadApi = new UploadApi(t);
            uploadApi.apiUploadPost({ file: f }).then(c => {
                console.log(c);
                let splits = c.split("|||");
                var sblob = new Blob([splits[0]], { type: 'text/plain' });
                var eblob = new Blob([splits[1]], { type: 'text/plain' });
                setHasUploadFinished(true);
                setUploadErrors(eblob);

                var sCount = parseInt(splits[2]);
                var eCount = parseInt(splits[3]);

                setUploadErrorsCount(eCount);
                setUploadSuccessCount(sCount)

                setUploadSuccess(sblob);



            }).then(e => {
                refreshAll();
            }).catch(e => {
                console.log(e);
            });
        });

        return true;
    }

    const updateRegistration = async (r: Registration) => {

        try {
            let t = await silentRequest();

            let registrationApi = new RegistrationApi(t);

            await registrationApi.apiRegistrationIdPut({ registration: r, id: r.registrationId!.toString() })

            setIsDirty(oldvalue => oldvalue + 1);
        }
        catch (e: unknown) {
            console.log(e);
        }
    }


    const refreshCurrentUser = async () => {

        try {

            let token = await silentRequest();

            let roomingUserApi = new RoomingUserApi(token);

            let user = await roomingUserApi.apiRoomingUserCurrentGet();

            if (user) {
                setCurrentUser(user);
            }
        }
        catch (e) {
            console.log(e);
        }

    }

    const addRegistration = async (r: Registration) => {

        try {
            let t = await silentRequest();
            let registrationApi = new RegistrationApi(t);
            await registrationApi.apiRegistrationPost({ registration: r })

            setIsDirty(oldvalue => oldvalue + 1);

        }
        catch (e: unknown) {
            console.log(e);
        }

    }

    const refreshAthletes = async () => {

        try {
            let token = await silentRequest();
            let athleteApi = new AthleteApi(token);
            let a = await athleteApi.apiAthleteGet();

            if (a) {
                setAthletes(a);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const refreshCountries = async () => {

        try {
            let token = await silentRequest();
            let roomingCountryAPI = new CountryApi(token);
            let c = await roomingCountryAPI.apiCountryGet();

            if (countries) {
                setCountries(c);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const refreshBuildings = async () => {

        try {
            let token = await silentRequest();
            let roomingBuildingAPI = new BuildingApi(token);
            let b = await roomingBuildingAPI.apiBuildingGet();

            if (b) {
                setBuildings(b);
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const silentRequest = () => {
        if (msalContext.accounts[0]) {
            const accessTokenRequest: SilentRequest = {
                scopes: apiScopes,
                account: msalContext.accounts[0] as AccountInfo,
                authority: apiAuthority,
            } as SilentRequest;

            return msalContext.instance
                .acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    const accessToken = response.accessToken;
                    let c: Configuration = new Configuration({
                        accessToken: accessToken,
                        basePath: backendBasePath,
                    });
                    return c;
                });
        }
        return Promise.reject();
    };

    const addBuilding = (b: Building) => {
        silentRequest().then(c => {
            let buildingsApi = new BuildingApi(c);
            buildingsApi.apiBuildingPost({ building: b });
        })
    }

    const addCountry = (c: Country) => {
        silentRequest().then(t => {
            let countriesApi = new CountryApi(t);
            countriesApi.apiCountryPost({ country: c });
        });
        refreshCountries();
    }

    const addUser = (u: RoomingUser) => {
        silentRequest().then(c => {
            let roomingUserApi = new RoomingUserApi(c);
            roomingUserApi.apiRoomingUserPost({ roomingUser: u });
        })
    }

    console.log('MainPageContext');


    return {
        showWelcomePage,
        setShowWelcomePage,
        isLoading,
        buildings,
        addBuilding,
        countries,
        addCountry,
        addUser,
        currentUser,
        athletes,
        addRegistration,
        uploadAthleteRegistration,
        downloadAthleteCsv,
        hasUploadFinished,
        setHasUploadFinished,
        refreshAll,
        uploadErrors,
        uploadSuccess,
        uploadErrorsCount,
        uploadSuccessCount,
        athleteListFilter,
        setAthleteListFilter,
        updateRegistration,
        searchTerm,
        setSearchTerm,
        filteredAthletes,
        refreshCache,
        downloadRegistrationsCsv,
        selectedDate,
        setSelectedDate,
        isDirty
    }
}