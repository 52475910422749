/* tslint:disable */
/* eslint-disable */
/**
 * Rooming_API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Country,
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
    Federation,
    FederationFromJSON,
    FederationFromJSONTyped,
    FederationToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoomingUser
 */
export interface RoomingUser {
    /**
     * 
     * @type {number}
     * @memberof RoomingUser
     */
    roomingUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    _function?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    emailExternal?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoomingUser
     */
    countryId?: number;
    /**
     * 
     * @type {Country}
     * @memberof RoomingUser
     */
    country?: Country;
    /**
     * 
     * @type {Federation}
     * @memberof RoomingUser
     */
    federation?: Federation;
    /**
     * 
     * @type {Array<Role>}
     * @memberof RoomingUser
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {Date}
     * @memberof RoomingUser
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RoomingUser
     */
    modified?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RoomingUser
     */
    deleted?: boolean;
    /**
     * 
     * @type {Array<Event>}
     * @memberof RoomingUser
     */
    events?: Array<Event> | null;
    /**
     * 
     * @type {Event}
     * @memberof RoomingUser
     */
    favoriteEvent?: Event;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomingUser
     */
    modifiedBy?: string | null;
}

export function RoomingUserFromJSON(json: any): RoomingUser {
    return RoomingUserFromJSONTyped(json, false);
}

export function RoomingUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomingUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomingUserId': !exists(json, 'roomingUserId') ? undefined : json['roomingUserId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailExternal': !exists(json, 'emailExternal') ? undefined : json['emailExternal'],
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'country': !exists(json, 'country') ? undefined : CountryFromJSON(json['country']),
        'federation': !exists(json, 'federation') ? undefined : FederationFromJSON(json['federation']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'events': !exists(json, 'events') ? undefined : (json['events'] === null ? null : (json['events'] as Array<any>).map(EventFromJSON)),
        'favoriteEvent': !exists(json, 'favoriteEvent') ? undefined : EventFromJSON(json['favoriteEvent']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
    };
}

export function RoomingUserToJSON(value?: RoomingUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomingUserId': value.roomingUserId,
        'title': value.title,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'function': value._function,
        'email': value.email,
        'emailExternal': value.emailExternal,
        'countryId': value.countryId,
        'country': CountryToJSON(value.country),
        'federation': FederationToJSON(value.federation),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleToJSON)),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'deleted': value.deleted,
        'events': value.events === undefined ? undefined : (value.events === null ? null : (value.events as Array<any>).map(EventToJSON)),
        'favoriteEvent': EventToJSON(value.favoriteEvent),
        'createdBy': value.createdBy,
        'modifiedBy': value.modifiedBy,
    };
}

