import { Container } from '@mui/material'
import React, { useState } from 'react'

import { MainPageContext, useMainPageContext } from '../../context/MainPageContext';

import UnauthorizedUserPage from '../UnauthorizedUserPage/UnauthorizedUserPage';
import AthleteDataGrid from './AthleteDataGrid/AthleteDataGrid';
import AppTopBar from './AppTopBar/AppTopBar';
import AppSideMenu from './AppSideMenu/AppSideMenu';

import {
    Route,
    Routes
} from "react-router-dom";
import AthleteDetailsPane from './AtheleteDetailsPane/AthleteDetailsPane';
import WelcomePage from '../WelcomePage/WelcomePage';
import AdminPage from '../AdminPage/AdminPage';
import StaffPage from '../StaffPage/StaffPage';
import { GetUserRole } from '../../utils/UserMapper';
import AdminCountryList from '../AdminPage/AdminCountryList/AdminCountryList';
import { AdminContext, useAdminContext } from '../../context/AdminContext';
import AthleteSchedule from '../AdminPage/AthleteSchedule/AthleteSchedule';

export default function MainPage() {


    const mainPageContext = useMainPageContext();
    const adminPageContext = useAdminContext();

    const [showDrawer, setShowDrawer] = useState(false);

    const menuButtonPressed = () => {
        setShowDrawer(!showDrawer);
    }

    return (
        <div>
            <MainPageContext.Provider value={mainPageContext}>
                {mainPageContext.currentUser &&
                    !GetUserRole(mainPageContext.currentUser) &&
                    <Container maxWidth={'lg'}>
                        <AppTopBar onDrawerOpen={menuButtonPressed}></AppTopBar>
                        <Routes>
                            <Route path="/" element={<AthleteDataGrid></AthleteDataGrid>} />
                            <Route path="/athlete/:athleteId" element={<AthleteDetailsPane />} />
                        </Routes>
                        <AppSideMenu isOpen={showDrawer} onClose={() => setShowDrawer(false)} onOpen={() => setShowDrawer(true)} ></AppSideMenu>
                        <WelcomePage show={mainPageContext.showWelcomePage} handleClose={() => { mainPageContext.setShowWelcomePage(false) }}></WelcomePage>
                    </Container>
                }
                <AdminContext.Provider value={adminPageContext}>
                {mainPageContext.currentUser &&
                    GetUserRole(mainPageContext.currentUser) === 'RoomingAdmin' &&
                    <Container maxWidth={'xl'}>
                        <AppTopBar onDrawerOpen={menuButtonPressed}></AppTopBar>
                        <Routes>
                            
                            <Route path="/" element={<StaffPage></StaffPage>} />
                            <Route path="/athletes" element={<AdminPage></AdminPage>} />
                            <Route path="/athlete/:athleteId" element={<AthleteDetailsPane />} />
                            <Route path="/countries" element={<AdminCountryList />} />
                            <Route path="/events/:accreditationNumber" element={<AthleteSchedule />} />
                        </Routes>
                        <AppSideMenu isOpen={showDrawer} onClose={() => setShowDrawer(false)} onOpen={() => setShowDrawer(true)} ></AppSideMenu>
                    </Container>
                }
                {mainPageContext.currentUser &&
                    GetUserRole(mainPageContext.currentUser) === 'RoomingStaff' &&
                    <Container maxWidth={'xl'}>
                        <AppTopBar onDrawerOpen={menuButtonPressed}></AppTopBar>
                        <Routes>
                            <Route path="/" element={<StaffPage></StaffPage>} />
                            <Route path="/athlete/:athleteId" element={<AthleteDetailsPane />} />
                            <Route path="/events/:accreditationNumber" element={<AthleteSchedule />} />
                        </Routes>
                        <AppSideMenu isOpen={showDrawer} onClose={() => setShowDrawer(false)} onOpen={() => setShowDrawer(true)} ></AppSideMenu>
                    </Container>
                }
                </AdminContext.Provider>
                {!mainPageContext.currentUser &&
                    <UnauthorizedUserPage></UnauthorizedUserPage>
                }
            </MainPageContext.Provider>
        </div>
    )
}

export const MemoizedMainPage = React.memo(MainPage);